import { Box, Image, Text, Flex, Button, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function Material() {
  return (
    <>
      <Box position="relative">
        <Image
          src={require('../images/bg_inscripciones.jpg')}
          w={'100vw'}
          objectFit="cover"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.35)"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Text
            color="white"
            fontSize={{ base: '28px', md: '100px' }}
            fontWeight="black"
          >
            MATERIAL
          </Text>
        </Box>
      </Box>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
        h={{ md: '40vh' }}
      >
        <Box
          mt={{ base: '30px', md: '30px' }}
          mb={{ base: '15px', md: '30px' }}
          ml={{ base: '0px', md: '30px' }}
          display="flex"
          justifyContent="center"
          flex={1}
        >
          <Stack spacing={4} align="center" mt="30px" mb="30px" mx="30px">
            <Stack
              spacing={4}
              direction={['column', 'column', 'row']}
              justifyContent="center"
            >
              <Button
                size="lg"
                color="white"
                bgGradient="linear(to-r, #91B451, #73A951)"
                _hover={{
                  bgGradient: 'linear(to-l, #91B451, #73A951)',
                }}
              >
                <Link
                  to="https://drive.google.com/drive/folders/1QcxeMVOmTc_QkA_hUFjV-EDdsLwYW_8j"
                  target="_blank"
                >
                  ASAMBLEA GENERAL
                </Link>
              </Button>

              <Button
                size="lg"
                color="white"
                bgGradient="linear(to-r, #DF2631, #A6213F)"
                _hover={{
                  bgGradient: 'linear(to-l, #DF2631, #A6213F)',
                }}
              >
                <Link
                  to="https://drive.google.com/drive/folders/1kLhQ0SYBVbR9NKtjJbe78yd14mJF_sko"
                  target="_blank"
                >
                  CONSEJO DE SEGURIDAD
                </Link>
              </Button>

              <Button
                size="lg"
                color="white"
                bgGradient="linear(to-r, #E97A28, #FB3729)"
                _hover={{
                  bgGradient: 'linear(to-l, #E97A28, #FB3729)',
                }}
              >
                <Link
                  to="https://drive.google.com/drive/folders/1qUGXRX0Ph5wQ8udBDbVw6W4MrkU5LtOK?usp=sharing"
                  target="_blank"
                >
                  ECOSOC
                </Link>
              </Button>
              <Button
                size="lg"
                color="white"
                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                _hover={{
                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                }}
              >
                <Link
                  to="https://drive.google.com/drive/folders/1-7_BYbuliaLd4AR5HXe9Rp7UvipUHwmp"
                  target="_blank"
                >
                  AUTORIDADES
                </Link>
              </Button>
            </Stack>

            <Stack
              spacing={4}
              direction={['column', 'column', 'row']}
              justifyContent="center"
            >
              <Button
                flexWrap="wrap"
                size="lg"
                color="white"
                bgGradient="linear(to-r, #FEB81A, #FF9D1C)"
                _hover={{
                  bgGradient: 'linear(to-l, #FEB81A, #FF9D1C)',
                }}
                whiteSpace="normal"
              >
                <Link
                  to="https://drive.google.com/drive/folders/1HdwgndvnPvqt6ayc5-b_QYaW1WurSXFs?usp=sharing"
                  target="_blank"
                >
                  SALA DE TRATADOS INTERNACIONALES
                </Link>
              </Button>
              <Button
                flexWrap="wrap"
                size="lg"
                color="white"
                bgGradient="linear(to-r, #7DCDFE, #62B4E4)"
                _hover={{
                  bgGradient: 'linear(to-l, #7DCDFE, #62B4E4)',
                }}
                whiteSpace="normal"
              >
                <Link
                  to="https://drive.google.com/drive/folders/1llYUqjhoMxWuV4kF2cCD6PKjJInq8rzc?usp=drive_link"
                  target="_blank"
                >
                  {' '}
                  CONSEJO DE DERECHOS HUMANOS
                </Link>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    </>
  );
}

export default Material;
