import { Box, Image, Text, Flex, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function CapsSuccess() {
  return (
    <>
      <Box position="relative">
        <Image
          src={require('../images/bg_inscripciones.jpg')}
          w={'100vw'}
          objectFit="cover"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.35)"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Text
            color="white"
            fontSize={{ base: '28px', md: '100px' }}
            fontWeight="black"
          >
            INSCRIPCIONES
          </Text>
        </Box>
      </Box>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
        /* h={{ md: '40vh' }} */
      >
        <Box
          /* ml={{ base: '30px', md: '100px' }} */
          mt={{ base: '10px', md: '30px' }}
          mb={{ base: '0px', md: '30px' }}
          color={'black'}
          p={4}
          flex={{ base: 0, md: 1 }}
          textAlign={'center'}
        >
          <Text
            /* color="white" */
            fontSize={{ base: '28px', md: '60px' }}
            fontWeight="black"
            textAlign={'center'}
          >
            REGISTRO EXITOSO
          </Text>
          <Text fontSize={{ base: '20px', md: '20px' }}>
            Su registro se ha realizado con <strong>éxito</strong>.
            <br /> A continuación, le pedimos que{' '}
            <strong>envie un mensaje al grupo</strong> que diga "Listo, esta muy
            linda la pagina" <br />
            <strong>
              LA ULTIMA PERSONA EN REGISTRARSE PAGA LAS BIRRAS EL SEGUNDO DIA
            </strong>
            .
          </Text>
        </Box>
      </Flex>
    </>
  );
}

export default CapsSuccess;
