import {
  Box,
  Flex,
  Image,
  useColorModeValue,
  Stack,
  Button,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  VStack,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        bgGradient={'linear(to-r, #233D72, #1E2F48)'}
        color={useColorModeValue('#F2F2F2', '#FFFFFF')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={'center'}
      >
        <Flex
          flex={{ base: 1 }}
          justify={{ base: 'left', md: 'left', sm: 'left' }}
        >
          <NavLink className="navbar-item" to="/">
            <Image
              src={require('../images/logo.png')}
              w={{ base: '100px', md: '150px' }}
            />
          </NavLink>
        </Flex>

        <Stack
          display={{ base: 'none', md: 'flex' }}
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          /* spacing={5} */
          /* mr={'20px'} */
        >
          <Button
            color={'white'}
            fontWeight={'500'}
            fontSize={'18px'}
            variant={'ghost'}
            onClick={() => navigate('/')}
          >
            <NavLink className="navbar-item" to="/">
              INICIO
            </NavLink>
          </Button>
          <Button
            color={'white'}
            fontWeight={'500'}
            fontSize={'18px'}
            mr={'20px'}
            variant={'ghost'}
          >
            <HashLink className="navbar-item" smooth to="/#us">
              QUIÉNES SOMOS
            </HashLink>
          </Button>
          <Button
            color={'white'}
            fontWeight={'500'}
            fontSize={'18px'}
            variant={'ghost'}
            onClick={() => navigate('/inscripciones')}
          >
            <NavLink className="navbar-item" to="/inscripciones">
              INSCRIPCIONES
            </NavLink>
          </Button>
          <Button
            color={'white'}
            fontWeight={'500'}
            fontSize={'18px'}
            variant={'ghost'}
            onClick={() => navigate('/material')}
          >
            <NavLink className="navbar-item" to="/material">
              MATERIAL
            </NavLink>
          </Button>
          <Button
            color={'white'}
            fontWeight={'500'}
            fontSize={'18px'}
            variant={'ghost'}
            onClick={() => navigate('/ingreso-docentes')}
          >
            <NavLink className="navbar-item" to="/ingreso-docentes">
              INGRESO DOCENTES
            </NavLink>
          </Button>
          <Button
            color={'white'}
            fontWeight={'500'}
            fontSize={'18px'}
            variant={'ghost'}
          >
            <HashLink className="navbar-item" smooth to="/#contacto">
              CONTACTO
            </HashLink>
          </Button>
        </Stack>

        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          variant="ghost"
          size="lg"
        />

        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton color={'white'} />
              <DrawerHeader
                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
              ></DrawerHeader>
              <DrawerBody bgGradient={'linear(to-r, #233D72, #1E2F48)'}>
                <VStack>
                  <Button
                    color={'white'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    variant={'ghost'}
                    onClick={onClose}
                  >
                    <NavLink className="navbar-item" to="/">
                      INICIO
                    </NavLink>
                  </Button>
                  <Button
                    color={'white'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    variant={'ghost'}
                    onClick={onClose}
                  >
                    <HashLink className="navbar-item" smooth to="/#us">
                      QUIÉNES SOMOS
                    </HashLink>
                  </Button>
                  <Button
                    color={'white'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    variant={'ghost'}
                    onClick={onClose}
                  >
                    <NavLink className="navbar-item" to="/inscripciones">
                      INSCRIPCIONES
                    </NavLink>
                  </Button>
                  <Button
                    color={'white'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    variant={'ghost'}
                    onClick={onClose}
                  >
                    <NavLink className="navbar-item" to="/material">
                      MATERIAL
                    </NavLink>
                  </Button>
                  <Button
                    color={'white'}
                    fontWeight={'500'}
                    fontSize={'18px'}
                    variant={'ghost'}
                    onClick={onClose}
                  >
                    <HashLink className="navbar-item" smooth to="/#contacto">
                      CONTACTO
                    </HashLink>
                  </Button>
                </VStack>
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Flex>
    </Box>
  );
}

export default Navbar;
