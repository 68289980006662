import { Box, Image, Text, Flex, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function Inscripciones() {
  const navigate = useNavigate();
  return (
    <>
      <Box position="relative">
        <Image
          src={require('../images/bg_inscripciones.jpg')}
          w={'100vw'}
          objectFit="cover"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.35)"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Text
            color="white"
            fontSize={{ base: '28px', md: '100px' }}
            fontWeight="black"
          >
            INSCRIPCIONES
          </Text>
        </Box>
      </Box>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
        h={{ md: '40vh' }}
      >
        <Box
          ml={{ base: '30px', md: '100px' }}
          mt={{ base: '10px', md: '30px' }}
          mb={{ base: '0px', md: '30px' }}
          color={'black'}
          p={4}
          flex={{ base: 0, md: 1 }}
        >
          <Text fontSize={{ base: '20px', md: '20px' }}>
            EL ORDEN DE INSCRIPCIÓN ES EL SIGUIENTE:
            <br /> EN PRIMER LUGAR, <strong>ESCUELAS</strong>
            <br /> EN SEGUNDO LUGAR, <strong>DOCENTES</strong>
            <br /> Y POR ÚLTIMO, <strong>DELEGADOS/AS, AUTORIDADES</strong>
          </Text>
        </Box>
        <Box
          mt={{ base: '30px', md: '30px' }}
          mb={{ base: '15px', md: '30px' }}
          ml={{ base: '0px', md: '30px' }}
          display="flex"
          justifyContent="center"
          flex={1}
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            wrap={'wrap'}
            flexWrap={'wrap'}
          >
            <Flex>
              <Button
                size={'lg'}
                color={'white'}
                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                _hover={{
                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                }}
                mb={2}
                onClick={() => navigate('escuelas')}
              >
                ESCUELAS
              </Button>
            </Flex>
            {/* <Flex>
              <Button
                size={'lg'}
                color={'white'}
                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                _hover={{
                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                }}
                mb={2}
                onClick={() => navigate('docentes')}
                isDisabled
              >
                DOCENTES
              </Button>
            </Flex> */}
            <Flex>
              <Button
                size={'lg'}
                color={'white'}
                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                _hover={{
                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                }}
                mb={2}
                onClick={() => navigate('delegados')}
              >
                DELEGADOS/AS
              </Button>
            </Flex>
            <Flex wrap={'wrap'}>
              <Button
                flexWrap={'wrap'}
                size={'lg'}
                color={'white'}
                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                _hover={{
                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                }}
                mb={2}
                whiteSpace={'normal'}
                w={{ base: '250px', md: '100%' }}
                h={{ base: '80px', md: '48px' }}
                onClick={() => navigate('autoridades')}
              >
                AUTORIDADES
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

export default Inscripciones;
