import React from 'react';
import { Box, Image, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Section1 from './Section1';
import { HashLink as Link } from 'react-router-hash-link';
import Section2 from './Section2';
import Contacto from './Contacto';

function Welcome() {
  return (
    <>
      <Box position="relative">
        <Image
          src={require('../images/bg2.png')}
          w={{ base: '150px', md: '250px' }}
          position="absolute"
          top={{ base: '50%', md: '40%' }}
          left="50%"
          transform="translate(-50%, -50%)"
        />
        <Link smooth to="#about">
          <Button
            borderRadius="50%"
            variant={'ghost'}
            width={{ base: '0px', md: '55px' }}
            height={{ base: '0px', md: '55px' }}
            border="2px solid"
            position={'absolute'}
            top={{ base: '75%', md: '70%' }}
            left="50%"
            transform="translate(-50%, -50%)"
            borderColor="white"
            color={'transparent'}
            size={{ md: 'lg' }}
            _hover={{
              backgroundColor: 'transparent',
              variant: 'ghost',
            }}
          >
            <ChevronDownIcon
              color={'white'}
              w={{ base: 0, md: 14 }}
              h={{ base: 0, md: 8 }}
              _hover={{
                backgroundColor: 'transparent',
                transform: 'scale(1.25)',
                transition: 'all 0.3s ease',
              }}
            />
          </Button>
        </Link>
        <Image src={require('../images/bg.jpg')} objectFit="cover" />
      </Box>
      <Section1 />
      <Section2 />
      <Contacto />
    </>
  );
}

export default Welcome;
