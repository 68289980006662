import { Flex, Box, Image, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function Section1() {
  const navigate = useNavigate();
  return (
    <>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box
          id="about"
          ml={{ base: '10px', md: '100px' }}
          mr={{ base: '10px', md: '0px' }}
          mt={{ base: '10px', md: '30px' }}
          mb={{ base: '0px', md: '30px' }}
          color={'black'}
          p={4}
          flex={{ base: 0, md: 1 }}
        >
          <Text
            fontSize={{ base: '20px', md: '40px' }}
            fontWeight={'black'}
            lineHeight={['30px', '42px']}
            mb={2}
            textAlign={{ base: 'center', md: 'left' }}
          >
            ¿Qué es el programa <br />
            Uniendo Metas?
          </Text>
          <Text textAlign={{ base: 'center', md: 'left' }}>
            Es uno de los programas de Asociación Conciencia que integra el eje
            de Participación, y su principal objetivo es preparar a los jóvenes
            para ejercer un liderazgo comprometido, activo y responsable. Está
            destinado a estudiantes de nivel secundario de escuelas de gestión
            estatal y privada de todo el país; quienes, a través de la
            capacitación y la participación en ejercicios de simulación y de
            diálogo, tienen la oportunidad de ampliar y poner en práctica sus
            conocimientos y experiencias, dialogar y fortalecer lazos sociales
            con sus pares para estar mejor preparados como tomadores de
            decisiones, tanto para su vida individual como social.
          </Text>
        </Box>
        <Box
          mt={{ base: '0px', md: '30px' }}
          mb={{ base: '15px', md: '30px' }}
          ml={{ base: '0px', md: '30px' }}
          display="flex"
          justifyContent="center"
          flex={1}
        >
          <Image
            borderRadius={'10px'}
            src={require('../images/section.jpg')}
            h={{ base: '200px', md: '300px' }}
          />
        </Box>
      </Flex>
      <Flex>
        <Box bg="gray.200" p={4} textAlign="center" w={'100vw'}>
          <Text
            fontSize={{ base: '20px', md: '40px' }}
            fontWeight={'black'}
            color={'black'}
            textAlign={{ base: 'center', md: 'center' }}
          >
            XV Encuentro Regional UM Bragado
          </Text>
          <Text
            fontSize={{ base: '20px', md: '20px' }}
            fontWeight={'bold'}
            color={'black'}
            textAlign={{ base: 'center', md: 'center' }}
          >
            28, 29 y 30 de agosto.
          </Text>
          <Text
            fontSize={{ base: '20px', md: '20px' }}
            fontWeight={'normal'}
            mb={3}
            color={'black'}
            textAlign={{ base: 'center', md: 'center' }}
          ></Text>
          <Button
            color={'white'}
            bgGradient="linear(to-br, #FB3729, #E97A28)"
            _hover={{
              bgGradient: 'linear(to-br, #BA2A1F, #E97A28)',
            }}
            mr={2}
            w={'166px'}
            fontSize={{ base: '20px', md: '20px' }}
            onClick={() => navigate('/inscripciones')}
          >
            Inscripciones
          </Button>
          <Button
            fontSize={{ base: '20px', md: '20px' }}
            color={'white'}
            bgGradient="linear(to-bl, #FB3729, #E97A28)"
            _hover={{
              bgGradient: 'linear(to-bl, #BA2A1F, #E97A28)',
            }}
            ml={{ base: 0, md: 2 }}
            w={'166px'}
            onClick={() => navigate('/material')}
          >
            Material
          </Button>
        </Box>
      </Flex>
      {/* <Flex>
        <Box bg="white" p={4} w={'100vw'}>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={'40px'}
            mt={'40px'}
          >
            <Box
              w="20%"
              h="334px"
              ml={'100px'}
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #7DCDFE,#62B4E4)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #91B451,#73A951)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #FEB81A,#FF9D1C)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              mr={'100px'}
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #DF2631,#A6213F)'}
            ></Box>
          </Box>
        </Box>
      </Flex> */}
    </>
  );
}

export default Section1;
