import React from 'react';
import Header from './components/Navbar';
import Welcome from './components/Welcome';
import Inscripciones from './components/Inscripciones';
import InscripcionEscuela from './components/InscripcionEscuela';
import InscripcionDocentes from './components/InscripcionDocente';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import InscripcionDelegados from './components/InscripcionDelegados';
import InscripcionAutoridades from './components/InscripcionAutoridades';
import Material from './components/Material';

import { useState, useEffect } from 'react';
import {
  getEscuelas,
  getDocentes,
  getEscuelasPendientes,
  getDocentesPendientes,
  getAutoridades,
  getDelegados,
  getCapacitadores,
  getPrensa,
  getCoordi,
} from './services/supabase';
import EscuelaSuccess from './components/EscuelaSuccess';
import DocenteSuccess from './components/DocenteSuccess';
import ParticipanteSuccess from './components/ParticipantesSuccess';
import AutoridadesSuccess from './components/AutoridadesSuccess';
import Admin from './components/Admin';
import Dashboard from './components/Dashboard';
import { supabase } from './services/supabase';
import InscripcionCapacitadores from './components/InscripcionCapacitadores';
import RegistroDocentes from './components/RegistroDocentes';
import IngresoDocente from './components/IngresoDocente';
import DashboardDocentes from './components/DashboardDocentes';
import CapsSuccess from './components/CapsSuccess';

function App() {
  const [school, setSchool] = useState([]);
  const [schoolPendiente, setSchoolPendiente] = useState([]);
  const [docente, setDocente] = useState([]);
  const [docentePendiente, setDocentePendiente] = useState([]);
  const [schoolsOutdated, setSchoolsOutdated] = useState(true);
  const [docentesOutdated, setDocentesOutdated] = useState(true);
  const [usuario, setUsuario] = useState([]);
  const [userOutdated, setUserOutdated] = useState(true);
  const [autoridades, setAutoridades] = useState([]);
  const [delegados, setDelegados] = useState([]);
  const [capacitadores, setCapacitadores] = useState([]);
  const [prensa, setPrensa] = useState([]);
  const [coordi, setCoordi] = useState([]);

  console.log(usuario);

  useEffect(() => {
    if (userOutdated) {
      async function traeruser() {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        const result = await getEscuelasPendientes();
        const result2 = await getDocentesPendientes();
        const result3 = await getEscuelas();
        const result4 = await getDocentes();
        const result5 = await getAutoridades();
        const result6 = await getDelegados();
        const result7 = await getCapacitadores();
        const result8 = await getPrensa();
        const result9 = await getCoordi();
        setSchoolPendiente(result);
        setDocentePendiente(result2);
        setSchool(result3);
        setDocente(result4);
        setAutoridades(result5);
        setDelegados(result6);
        setCapacitadores(result7);
        setPrensa(result8);
        setCoordi(result9);
        setUsuario(user);
        setUserOutdated(false);
      }
      traeruser();
    }
  }, [
    setUsuario,
    userOutdated,
    setUserOutdated,
    setSchool,
    setDocente,
    setSchoolPendiente,
    setDocentePendiente,
    setAutoridades,
    setDelegados,
    setCapacitadores,
    setPrensa,
    setCoordi,
  ]);

  useEffect(() => {
    if (schoolsOutdated) {
      async function traerEscuelas() {
        const result = await getEscuelas();
        const result2 = await getEscuelasPendientes();
        setSchoolPendiente(result2);
        setSchool(result);
        setSchoolsOutdated(false);
      }
      traerEscuelas();
    }
  }, [setSchool, schoolsOutdated, setSchoolsOutdated]);

  useEffect(() => {
    if (docentesOutdated) {
      async function traerDocentes() {
        const result2 = await getDocentes();
        const result = await getDocentesPendientes();
        setDocentePendiente(result);
        setDocente(result2);
        setDocentesOutdated(false);
      }
      traerDocentes();
    }
  }, [setDocente, docentesOutdated, setDocentesOutdated]);

  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route
            path="/admin"
            element={<Admin setUserOutdated={setUserOutdated} />}
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                usuario={usuario}
                school={school}
                docente={docente}
                autoridades={autoridades}
                delegados={delegados}
                capacitadores={capacitadores}
                prensa={prensa}
                coordi={coordi}
                schoolPendiente={schoolPendiente}
                docentePendiente={docentePendiente}
                setSchoolsOutdated={setSchoolsOutdated}
                setDocentesOutdated={setDocentesOutdated}
              />
            }
          />
          <Route
            path="/dashboard-docentes"
            element={
              <DashboardDocentes
                usuario={usuario}
                school={school}
                docente={docente}
                autoridades={autoridades}
                delegados={delegados}
                capacitadores={capacitadores}
                prensa={prensa}
                coordi={coordi}
                schoolPendiente={schoolPendiente}
                docentePendiente={docentePendiente}
                setSchoolsOutdated={setSchoolsOutdated}
                setDocentesOutdated={setDocentesOutdated}
              />
            }
          />
          <Route
            path="/registro"
            element={
              <InscripcionCapacitadores setUserOutdated={setUserOutdated} />
            }
          />
          <Route path="/registro/success" element={<CapsSuccess />} />
          <Route path="/inscripciones" element={<Inscripciones />} />
          <Route path="/material" element={<Material />} />
          {/* <Route path="/material/ag" element={<AG />} />
          <Route path="/material/cs" element={<CS />} />
          <Route path="/material/ecosoc" element={<Ecosoc />} />
          <Route path="/material/sati" element={<SATI />} />
          <Route path="/material/cdh" element={<CDH />} /> */}
          <Route
            path="/ingreso-docentes"
            element={<IngresoDocente setUserOutdated={setUserOutdated} />}
          />
          <Route
            path="/inscripciones/escuelas"
            element={
              <InscripcionEscuela
                setSchoolsOutdated={setSchoolsOutdated}
                setUserOutdated={setUserOutdated}
              />
            }
          />
          <Route
            path="/inscripciones/escuelas/success"
            element={<EscuelaSuccess />}
          />
          <Route
            path="/inscripciones/registrodocentes"
            element={
              <RegistroDocentes
                setUserOutdated={setUserOutdated}
                setUsuario={setUsuario}
                usuario={usuario}
              />
            }
          />
          <Route
            path="/inscripciones/docentes"
            element={
              <InscripcionDocentes
                school={school}
                setSchoolsOutdated={setSchoolsOutdated}
                setDocentesOutdated={setDocentesOutdated}
                setUserOutdated={setUserOutdated}
                usuario={usuario}
              />
            }
          />
          <Route
            path="/inscripciones/docentes/success"
            element={<DocenteSuccess />}
          />
          <Route
            path="/inscripciones/delegados"
            element={
              <InscripcionDelegados
                school={school}
                docente={docente}
                setSchoolsOutdated={setSchoolsOutdated}
                setDocentesOutdated={setDocentesOutdated}
                setUserOutdated={setUserOutdated}
              />
            }
          />
          <Route
            path="/inscripciones/delegados/success"
            element={<ParticipanteSuccess />}
          />
          <Route
            path="/inscripciones/autoridades"
            element={
              <InscripcionAutoridades
                school={school}
                docente={docente}
                setSchoolsOutdated={setSchoolsOutdated}
                setDocentesOutdated={setDocentesOutdated}
                setUserOutdated={setUserOutdated}
              />
            }
          />
          <Route
            path="/inscripciones/autoridades/success"
            element={<AutoridadesSuccess />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
