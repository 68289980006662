import { extendTheme } from '@chakra-ui/react';
import './fonts/GothamPro.woff2';

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  fonts: {
    heading: `'Gotham Pro', sans-serif`,
    body: `'Gotham Pro', sans-serif`,
  },
  shadows: {
    light: '0 0 0 3px rgba(99, 179, 237,1)',
  },
  config,
});

export default theme;
