import { Flex, Box, Image, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function Section2() {
  return (
    <>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box
          mt={{ base: '30px', md: '50px' }}
          mb={{ base: '15px', md: '30px' }}
          ml={{ base: '0px', md: '0px' }}
          display="flex"
          justifyContent="center"
          flex={1}
          id="nosotros"
        >
          <Image
            borderRadius={'10px'}
            src={require('../images/nosotros2.jpg')}
            h={{ base: '200px', md: '300px' }}
          />
        </Box>
        <Box
          id="us"
          mr={{ base: '10px', md: '30px' }}
          ml={{ base: '10px', md: '0px' }}
          mt={{ base: '10px', md: '30px' }}
          mb={{ base: '0px', md: '30px' }}
          color={'black'}
          p={2}
          flex={{ base: 0, md: 1 }}
        >
          <Text
            fontSize={{ base: '20px', md: '40px' }}
            fontWeight={'black'}
            mb={2}
            textAlign={{ base: 'center', md: 'left' }}
          >
            Quiénes somos
          </Text>
          <Text textAlign={{ base: 'center', md: 'left' }}>
            Somos un equipo de voluntarios conformado por estudiantes
            universitarios que llevamos a cabo{' '}
            <strong> Uniendo Metas Bragado </strong> el programa de Asociación
            Conciencia que utiliza la metodología de los Modelos de Naciones
            Unidas para promover el ejercicio de un <em>liderazgo positivo</em>{' '}
            en los jóvenes de escuela secundaria, a través del desarrollo de las{' '}
            <em>habilidades para el siglo XXI.</em>
            <br />
            <br />
            El primer encuentro <strong> Uniendo Metas Bragado </strong> se
            realizó en el año 1995
            <br />
            <br />
            En el transcurso de estos 29 años, contamos con el acompañamiento de{' '}
            <strong>
              Acerbrag, la Municipalidad de Bragado, Eduardo Beraza S.A., La
              Bragadense S.A., el Consejo Escolar, la Jefatura Distrital, el
              Concejo Deliberante, la Dirección de Juventudes, las Instituciones
              Educativas,
            </strong>{' '}
            y demás entidades bragadenses y de la zona que hacen posible la
            organización de este encuentro.
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Box bg="gray.200" p={4} textAlign="center" w={'100vw'}>
          <Flex justify="center" align={'center'}>
            <Box>
              {/* Logo 3 */}
              <Image
                src={require('../images/logoccc2.png')}
                h={{ base: '40px', md: '90px' }}
                mr={[2, 8]}
                ml={[0, 8]}
              />
            </Box>
            <Box>
              {/* Logo 4 */}
              <Image
                mr={[2, 8]}
                ml={[0, 8]}
                src={require('../images/acerbrag2.png')}
                h={{ base: '40px', md: '100px' }}
              />
            </Box>
            <Box>
              {/* Logo 2 */}
              <Image
                src={require('../images/logo_mb.png')}
                w={{ base: '40px', md: '120px' }}
                mr={[2, 8]}
                ml={[2, 8]}
              />
            </Box>
            <Box>
              {/* Logo 5 */}
              <Image
                src={require('../images/beraza3.png')}
                mr={[, 8]}
                ml={[2, 8]}
                h={{ base: '40px', md: '100px' }}
              />
            </Box>
            <Box>
              {/* Logo 5 */}
              <Image
                src={require('../images/lb2024.png')}
                mr={[, 8]}
                ml={[2, 8]}
                h={{ base: '40px', md: 'auto' }}
                w={{ base: 'auto', md: '200px' }}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>

      {/* <Flex>
        <Box bg="white" p={4} w={'100vw'}>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={'40px'}
            mt={'40px'}
          >
            <Box
              w="20%"
              h="334px"
              ml={'100px'}
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #7DCDFE,#62B4E4)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #91B451,#73A951)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #FEB81A,#FF9D1C)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              mr={'100px'}
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #DF2631,#A6213F)'}
            ></Box>
          </Box>
        </Box>
      </Flex> */}
    </>
  );
}

export default Section2;
