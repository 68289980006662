import {
  Button,
  FormControl,
  FormLabel,
  useColorModeValue,
  Input,
  Stack,
  Box,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import useForm from '../hooks/useForm';
import { useState } from 'react';
import { signInWithEmail } from '../services/supabase';
import { useNavigate } from 'react-router-dom';

const initialState = {
  email: '',
  password: '',
};

function Admin({ setUserOutdated }) {
  const { formValues, handleInputChange } = useForm(initialState);
  const [errorMessage, setErrorMessage] = useState('');
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    const { email, password } = formValues;
    const result = await signInWithEmail(email, password);
    console.log(result);
    if (result.error && result.error.message === 'Invalid login credentials') {
      setErrorMessage('La cuenta o la contraseña es incorrecta');
    }
    if (result.error === null) {
      navigate('/dashboard');
      setUserOutdated(true);
    }

    return result;
  };
  const bgColor2 = useColorModeValue('#181718', '#FFFFFF');
  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} alignItems={{ base: 'center', sm: 'center' }}>
          <Box
            marginLeft={{ base: 2, sm: 0 }}
            w={{ base: 'xs', sm: 'sm' }}
            h={'50vh'}
            borderRadius="lg"
            mt={4}
            display={'flex'}
            flexDir={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <FormControl
              id="email"
              display={'flex'}
              flexDirection="column"
              isInvalid={errorMessage}
            >
              <FormLabel
                ml={{ base: 0, sm: 3 }}
                color={bgColor2}
                display={'flex'}
                fontWeight="700"
              >
                Email
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                flexDirection="column"
                borderRadius="2rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese su email..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                w={{ base: '314px', sm: '370px' }}
                overflow="hidden"
                mb={2}
                ml={{ base: 0, sm: '7px' }}
                type="email"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl id="password" isInvalid={errorMessage}>
              <FormLabel
                ml={{ base: 0, sm: 3 }}
                color={bgColor2}
                display={'flex'}
                fontWeight="700"
              >
                Contraseña
              </FormLabel>
              <InputGroup
                w={{ base: '314px', sm: '370px' }}
                ml={{ base: 0, sm: '7px' }}
              >
                <Input
                  color={'#181718'}
                  borderRadius="2rem"
                  bg={'#F2F2F2'}
                  placeholder="Ingrese su contraseña..."
                  _placeholder={{ opacity: 1, color: 'gray.500' }}
                  overflow="hidden"
                  mb={errorMessage ? 0 : 2}
                  type={show ? 'text' : 'password'}
                  pr={'5rem'}
                  name="password"
                  value={formValues.password}
                  onChange={handleInputChange}
                  minLength={6}
                  required
                />
                <InputRightElement width="4.5rem" mr={3}>
                  <Button
                    borderRadius="2rem"
                    h="1.75rem"
                    fontWeight={'700'}
                    size="sm"
                    onClick={handleClick}
                    bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                    _hover={{
                      bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                    }}
                  >
                    {show ? 'Ocultar' : 'Mostrar'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage ml={3}>{errorMessage}</FormErrorMessage>
            </FormControl>

            <Button
              type="submit"
              fontSize="xl"
              mt={2}
              fontWeight={'700'}
              borderRadius="2rem"
              w={{ base: '314px', sm: '370px' }}
              marginLeft={{ base: 0, sm: 0 }}
              bgGradient={'linear(to-r, #233D72, #1E2F48)'}
              _hover={{
                bgGradient: 'linear(to-l, #233D72, #1E2F48)',
              }}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  );
}
export default Admin;
