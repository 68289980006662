import {
  Text,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  Accordion,
  AccordionButton,
  AccordionItem,
  Flex,
  AccordionPanel,
  Radio,
  RadioGroup,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';

import useForm from '../hooks/useForm';
import { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { insertCapacitadores } from '../services/supabase';
import { useNavigate } from 'react-router-dom';

const initialState = {
  nombreRef: '',
  apellidoRef: '',
  phoneRef: '',
  emailRef: '',
  dniRef: '',
  fechaRef: '',
  sanguineoRef: '',
  factorRef: '',
  obraRef: '',
  numeroRef: '',
  contactoRef: '',
  parentescoRef: '',
  telRef: '',
  medicoRef: '',
  telmedicoRef: '',
  derivarRef: '',
  enfermedadRef: '',
  medicamentoRef: '',
  antecedentesRef: '',
  alergiasRef: '',
  dietaRef: '',
};

function InscripcionCapacitadores({ setUserOutdated }) {
  const { formValues, handleInputChange, reset } = useForm(initialState);

  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const [isSectionOpen2, setIsSectionOpen2] = useState(true);
  const [acerbrag, setAcerbrag] = useState('');
  const [enfermedad, setEnfermedad] = useState('');
  const [medicamento, setMedicamento] = useState('');
  const [antecedentes, setAntecedentes] = useState('');
  const [alergias, setAlergias] = useState('');
  const [dieta, setDieta] = useState('');
  const [acerbragMessage, setAcerbragMessage] = useState('');
  const [enfermedadMessage, setEnfermedadMessage] = useState('');
  const [medicamentoMessage, setMedicamentoMessage] = useState('');
  const [antecedentesMessage, setAntecedentesMessage] = useState('');
  const [alergiasMessage, setAlergiasMessage] = useState('');
  const [dietaMessage, setDietaMessage] = useState('');
  const navigate = useNavigate();

  const handleSectionToggle = () => {
    setIsSectionOpen(!isSectionOpen);
  };

  const handleSectionToggle2 = () => {
    setIsSectionOpen2(!isSectionOpen2);
  };

  const handleAcerbragChange = value => {
    setAcerbrag(value);
    setAcerbragMessage('');
  };

  const handleEnfermedadChange = value => {
    setEnfermedad(value);
    setEnfermedadMessage('');
  };

  const handleMedicamentoChange = value => {
    setMedicamento(value);
    setMedicamentoMessage('');
  };

  const handleAntecedentesChange = value => {
    setAntecedentes(value);
    setAntecedentesMessage('');
  };

  const handleAlergiasChange = value => {
    setAlergias(value);
    setAlergiasMessage('');
  };

  const handleDietaChange = value => {
    setDieta(value);
    setDietaMessage('');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const {
      nombreRef,
      apellidoRef,
      phoneRef,
      emailRef,
      dniRef,
      fechaRef,
      sanguineoRef,
      factorRef,
      obraRef,
      numeroRef,
      contactoRef,
      parentescoRef,
      telRef,
      medicoRef,
      telmedicoRef,
      derivarRef,
      enfermedadRef,
      medicamentoRef,
      antecedentesRef,
      alergiasRef,
      dietaRef,
    } = formValues;

    const data = {
      nombre: nombreRef,
      apellido: apellidoRef,
      telefono: phoneRef,
      email: emailRef,
      dni: dniRef,
      fechanacimiento: fechaRef,
      sanguineo: sanguineoRef,
      factor: factorRef,
      obra: obraRef,
      numero: numeroRef,
      contacto_emergencia: contactoRef,
      parentesco: parentescoRef,
      tel: telRef,
      medico: medicoRef,
      telmedico: telmedicoRef,
      derivar: derivarRef,
      enfermedadref: enfermedadRef,
      enfermedad: enfermedad,
      medicamentoref: medicamentoRef,
      medicamento: medicamento,
      antecedentesref: antecedentesRef,
      antecedentes: antecedentes,
      alergiasref: alergiasRef,
      alergias: alergias,
      dietaref: dietaRef,
      dieta: dieta,
      acerbrag: acerbrag,
    };
    if (
      acerbrag !== '' &&
      enfermedad !== '' &&
      medicamento !== '' &&
      antecedentes !== '' &&
      alergias !== '' &&
      dieta !== ''
    ) {
      const result = await insertCapacitadores(data);
      if (result.error === null) {
        console.log(result);
        setUserOutdated(true);
        reset();
        navigate('success');
      } else {
        console.log(result.error);
      }
    } else {
      if (acerbrag === '') {
        setAcerbragMessage('Este campo es requerido');
      }
      if (enfermedad === '') {
        setEnfermedadMessage('Este campo es requerido');
      }
      if (medicamento === '') {
        setMedicamentoMessage('Este campo es requerido');
      }
      if (antecedentes === '') {
        setAntecedentesMessage('Este campo es requerido');
      }
      if (alergias === '') {
        setAlergiasMessage('Este campo es requerido');
      }
      if (dieta === '') {
        setDietaMessage('Este campo es requerido');
      }
    }
  };

  return (
    <>
      <Text
        /* color="white" */
        fontSize={{ base: '20px', md: '60px' }}
        fontWeight="black"
        textAlign={'center'}
        mt={4}
      >
        REGISTRO VOLUNTARIOS/AS
      </Text>
      <form onSubmit={handleSubmit}>
        <Accordion defaultIndex={[0, 1]} allowMultiple>
          <AccordionItem>
            <Stack
              spacing={2}
              mt={4}
              alignItems={{ base: 'center', sm: 'center' }}
            >
              <Box
                marginLeft={{ base: 2, sm: 0 }}
                w={{ base: 'auto', md: 'lg' }}
                borderRadius="lg"
              >
                <AccordionButton onClick={handleSectionToggle}>
                  <Box as="span" flex="1" textAlign="left">
                    <Flex alignItems="center">
                      <Text
                        fontSize={{ base: '18px', md: '30px' }}
                        fontWeight="black"
                        textAlign="left"
                        mb={4}
                      >
                        Datos Personales
                      </Text>
                      {isSectionOpen ? (
                        <ArrowUpIcon boxSize={6} mb={4} ml={2} />
                      ) : (
                        <ArrowDownIcon boxSize={6} mb={4} ml={2} />
                      )}
                    </Flex>
                  </Box>
                </AccordionButton>

                <AccordionPanel>
                  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  >
                    <FormControl
                      id="nombreRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        ml={{ base: 0, sm: 3 }}
                        display={'flex'}
                        fontWeight="700"
                        htmlFor="nombreRef"
                      >
                        Nombre
                      </FormLabel>
                      <Input
                        id="nombreRef"
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese su nombre..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="nombreRef"
                        value={formValues.nombreRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                    <FormControl
                      id="apellidoRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        /* ml={{ base: 0, sm: 3 }} */
                        display={'flex'}
                        fontWeight="700"
                      >
                        Apellido
                      </FormLabel>
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese su apellido..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        /* ml={{ base: 0, sm: '7px' }} */
                        mb={2}
                        type="text"
                        name="apellidoRef"
                        value={formValues.apellidoRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </HStack>

                  <FormControl
                    id="fechaRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Fecha de nacimiento
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese su fecha de nacimiento..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="date"
                      name="fechaRef"
                      value={formValues.fechaRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="phoneRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Telefono (Sin espacios ni guiones)
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el numero de telefono..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="phone"
                      name="phoneRef"
                      value={formValues.phoneRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="emailRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Email
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el email..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="email"
                      name="emailRef"
                      value={formValues.emailRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="dniRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      DNI
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese su DNI..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="dniRef"
                      value={formValues.dniRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="acerbragRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={acerbragMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Tiene algun pariente trabajando en Acerbrag? (Madre,
                      padre, hermanos/as)
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleAcerbragChange}
                      value={acerbrag}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {acerbragMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {acerbragMessage}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </AccordionPanel>
              </Box>
            </Stack>
          </AccordionItem>

          <AccordionItem>
            <Stack
              spacing={2}
              mt={4}
              alignItems={{ base: 'center', sm: 'center' }}
            >
              <Box
                marginLeft={{ base: 2, sm: 0 }}
                w={{ base: 'auto', md: 'lg' }}
                borderRadius="lg"
              >
                <AccordionButton onClick={handleSectionToggle2}>
                  <Box as="span" flex="1" textAlign="left">
                    <Flex alignItems="center">
                      <Text
                        fontSize={{ base: '18px', md: '30px' }}
                        fontWeight="black"
                        textAlign="left"
                        mb={4}
                      >
                        Datos Médicos
                      </Text>
                      {isSectionOpen2 ? (
                        <ArrowUpIcon boxSize={6} mb={4} ml={2} />
                      ) : (
                        <ArrowDownIcon boxSize={6} mb={4} ml={2} />
                      )}
                    </Flex>
                  </Box>
                </AccordionButton>

                <AccordionPanel>
                  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  >
                    <FormControl
                      id="sanguineoRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        ml={{ base: 0, sm: 3 }}
                        display={'flex'}
                        fontWeight="700"
                      >
                        Grupo Sanguíneo
                      </FormLabel>
                      <Select
                        display={'flex'}
                        borderRadius="1rem"
                        placeholder="Seleccione grupo sanguíneo..."
                        _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        name="sanguineoRef"
                        value={formValues.sanguineoRef}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="0">0</option>
                        <option value="AB">AB</option>
                      </Select>
                    </FormControl>
                    <FormControl
                      id="factorRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        /* ml={{ base: 0, sm: 3 }} */
                        display={'flex'}
                        fontWeight="700"
                      >
                        Factor RH
                      </FormLabel>
                      <Select
                        display={'flex'}
                        borderRadius="1rem"
                        placeholder="Seleccione factor RH..."
                        _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        mb={2}
                        name="factorRef"
                        value={formValues.factorRef}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="+">Positivo</option>
                        <option value="-">Negativo</option>
                      </Select>
                    </FormControl>
                  </HStack>
                  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  >
                    <FormControl
                      id="obraRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        ml={{ base: 0, sm: 3 }}
                        display={'flex'}
                        fontWeight="700"
                      >
                        Obra Social
                      </FormLabel>
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese obra social..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="obraRef"
                        value={formValues.obraRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                    <FormControl
                      id="numeroRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        /* ml={{ base: 0, sm: 3 }} */
                        display={'flex'}
                        fontWeight="700"
                      >
                        Número de Afiliado
                      </FormLabel>
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese numero afiliado..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        /* ml={{ base: 0, sm: '7px' }} */
                        mb={2}
                        type="text"
                        name="numeroRef"
                        value={formValues.numeroRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </HStack>
                  {/*  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  > */}
                  <FormControl
                    id="contactoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Nombre Contacto de Emergencia
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese nombre de contacto de emergencia..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="contactoRef"
                      value={formValues.contactoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="parentescoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Parentesco del Contacto de Emergencia
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Informe el parentesco..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="parentescoRef"
                      value={formValues.parentescoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  {/*  </HStack> */}
                  <FormControl
                    id="telRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Telefono Contacto de Emergencia
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el telefono..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="phone"
                      name="telRef"
                      value={formValues.telRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="medicoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Nombre y apellido médico de cabecera
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el nombre y apellido..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="medicoRef"
                      value={formValues.medicoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="telmedicoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Telefono médico de cabecera
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el telefono del medico..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="telmedicoRef"
                      value={formValues.telmedicoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="derivarRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      ¿A qué lugar derivar en caso de urgencia?
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Indique a donde derivar en una urgencia..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="derivarRef"
                      value={formValues.derivarRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <Text
                    ml={{ base: 0, sm: 3 }}
                    display={'flex'}
                    fontWeight="700"
                    mb={2}
                    mt={2}
                  >
                    Complete las siguientes preguntas:
                  </Text>
                  <FormControl
                    id="enfermedadRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={enfermedadMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Padece alguna enfermedad crónica?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleEnfermedadChange}
                      value={enfermedad}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {enfermedadMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {enfermedadMessage}
                      </FormErrorMessage>
                    )}
                    {enfermedad == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="enfermedadRef"
                        value={formValues.enfermedadRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="medicamentoRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={medicamentoMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Está tomando algún medicamento?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleMedicamentoChange}
                      value={medicamento}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {medicamentoMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {medicamentoMessage}
                      </FormErrorMessage>
                    )}
                    {medicamento == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="medicamentoRef"
                        value={formValues.medicamentoRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="antecedentesRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={antecedentesMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      Antecedentes (Operaciones, convulsiones, etc.)
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleAntecedentesChange}
                      value={antecedentes}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {antecedentesMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {antecedentesMessage}
                      </FormErrorMessage>
                    )}
                    {antecedentes == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="antecedentesRef"
                        value={formValues.antecedentesRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="alergiasRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={alergiasMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Padece alergias de algún tipo?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleAlergiasChange}
                      value={alergias}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {alergiasMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {alergiasMessage}
                      </FormErrorMessage>
                    )}
                    {alergias == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="alergiasRef"
                        value={formValues.alergiasRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="dietaRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={dietaMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Debe seguir alguna dieta especial?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleDietaChange}
                      value={dieta}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {dietaMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {dietaMessage}
                      </FormErrorMessage>
                    )}
                    {dieta == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="dietaRef"
                        value={formValues.dietaRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                </AccordionPanel>
              </Box>
            </Stack>
          </AccordionItem>
          <Stack spacing={2} alignItems={{ base: 'center', sm: 'center' }}>
            <Button
              w={{ base: '314px', md: '505px' }}
              mb={4}
              type="submit"
              borderRadius="2rem"
              marginLeft={{ base: 0, sm: 0 }}
              fontSize={'xl'}
              fontWeight="700"
              loadingText="Submitting"
              bgGradient={'linear(to-r, #233D72, #1E2F48)'}
              color={'#FFFFFF'}
              _hover={{
                bgGradient: 'linear(to-tl, #233D72, #1E2F48)',
              }}
            >
              Registrarse
            </Button>
          </Stack>
        </Accordion>
      </form>
    </>
  );
}

export default InscripcionCapacitadores;
