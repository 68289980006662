import {
  Text,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
} from '@chakra-ui/react';

import useForm from '../hooks/useForm';
import { useState, useEffect } from 'react';
import { insertEscuelasPendientes } from '../services/supabase';
import { useNavigate } from 'react-router-dom';

const initialState = {
  nombreRef: '',
  cueRef: '',
  phoneRef: '',
  emailRef: '',
  provinciaRef: '',
  localidadRef: '',
  directorRef: '',
};

function InscripcionEscuelas({ setSchoolsOutdated, setUserOutdated }) {
  const { formValues, handleInputChange, reset } = useForm(initialState);
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://apis.datos.gob.ar/georef/api/provincias')
      .then(response => response.json())
      .then(data => {
        const sortedProvinces = data.provincias
          .map(provincia => provincia.nombre)
          .sort();
        setProvinces(sortedProvinces);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const handleProvinceChange = event => {
    setSelectedProvince(event.target.value);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { nombreRef, cueRef, phoneRef, emailRef, localidadRef, directorRef } =
      formValues;

    const data = {
      nombre: nombreRef,
      cue: cueRef,
      telefono: phoneRef,
      email: emailRef,
      localidad: localidadRef,
      autoridad: directorRef,
      provincia: selectedProvince,
    };

    if (selectedProvince !== '') {
      const result = await insertEscuelasPendientes(data);
      if (result.error === null) {
        console.log(result);
        setSchoolsOutdated(true);
        setUserOutdated(true);
        reset();
        navigate('success');
      } else {
        console.log(result.error);
      }
    } else {
      if (selectedProvince === '') {
        alert('Debe seleccionar una provincia');
      }
    }
  };

  return (
    <>
      <Text
        /* color="white" */
        fontSize={{ base: '28px', md: '60px' }}
        fontWeight="black"
        textAlign={'center'}
        mt={4}
      >
        INSCRIPCIÓN ESCUELA
      </Text>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} mt={4} alignItems={{ base: 'center', sm: 'center' }}>
          <Box
            marginLeft={{ base: 2, sm: 0 }}
            w={{ base: 'xs', md: 'lg' }}
            borderRadius="lg"
          >
            <FormControl id="nombreRef" display="flex" flexDirection={'column'}>
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                Nombre del establecimiento
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                borderRadius="1rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese el nombre del establecimiento..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                overflow="hidden"
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                mb={2}
                type="text"
                name="nombreRef"
                value={formValues.nombreRef}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl id="cueRef" display="flex" flexDirection={'column'}>
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                CUE
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                borderRadius="1rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese el CUE del establecimiento..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                overflow="hidden"
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                mb={2}
                type="text"
                name="cueRef"
                value={formValues.cueRef}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl id="phoneRef" display="flex" flexDirection={'column'}>
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                Telefono (Sin espacios ni guiones)
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                borderRadius="1rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese el numero de telefono..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                overflow="hidden"
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                mb={2}
                type="phone"
                name="phoneRef"
                value={formValues.phoneRef}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl id="emailRef" display="flex" flexDirection={'column'}>
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                Email
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                borderRadius="1rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese el email..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                overflow="hidden"
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                mb={2}
                type="email"
                name="emailRef"
                value={formValues.emailRef}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              id="provinciaRef"
              display="flex"
              flexDirection={'column'}
            >
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                Provincia
              </FormLabel>
              <Select
                placeholder="Seleccione una provincia..."
                _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                /* color={'#181718'} */
                value={selectedProvince}
                /* bgColor={'white'} */
                onChange={handleProvinceChange}
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                borderRadius="1rem"
                mb={2}
              >
                {provinces.map(province => (
                  <option key={province} value={province}>
                    {province}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              id="localidadRef"
              display="flex"
              flexDirection={'column'}
            >
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                Localidad
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                borderRadius="1rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese la localidad..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                overflow="hidden"
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                mb={2}
                type="text"
                name="localidadRef"
                value={formValues.localidadRef}
                onChange={handleInputChange}
                required
              />
            </FormControl>
            <FormControl
              id="directorRef"
              display="flex"
              flexDirection={'column'}
            >
              <FormLabel
                /* mt={{ base: 2, sm: 4 }} */
                ml={{ base: 0, sm: 3 }}
                display={'flex'}
                fontWeight="700"
              >
                Nombre de la Autoridad del Establecimiento
              </FormLabel>
              <Input
                color={'#181718'}
                display={'flex'}
                borderRadius="1rem"
                bg={'#F2F2F2'}
                placeholder="Ingrese el nombre de la autoridad..."
                _placeholder={{ opacity: 1, color: 'gray.500' }}
                overflow="hidden"
                w={{ base: '314px', md: 'auto' }}
                ml={{ base: 0, sm: '7px' }}
                mb={2}
                type="text"
                name="directorRef"
                value={formValues.directorRef}
                onChange={handleInputChange}
                required
              />
            </FormControl>
          </Box>
          <Stack spacing={2}>
            <Button
              w={{ base: '314px', md: '505px' }}
              mb={4}
              mt={2}
              borderRadius="2rem"
              marginLeft={{ base: 0, sm: 0 }}
              fontSize={'xl'}
              fontWeight="700"
              bgGradient={'linear(to-r, #233D72, #1E2F48)'}
              color={'#FFFFFF'}
              _hover={{
                bgGradient: 'linear(to-tl, #233D72, #1E2F48)',
              }}
              type="submit"
            >
              Registrarse
            </Button>
          </Stack>
        </Stack>
      </form>
    </>
  );
}

export default InscripcionEscuelas;
