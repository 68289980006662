import {
  Table,
  Box,
  Button,
  Tooltip,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Card,
  CardBody,
  StackDivider,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  ModalContent,
  ModalHeader,
  Stack,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  HStack,
  InputGroup,
  Input,
  InputRightElement,
  Select,
} from '@chakra-ui/react';
import { CheckIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import {
  insertEscuelas,
  insertDocentes,
  deleteEscuelaPendientes,
  deleteDocentesPendientes,
} from '../services/supabase';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';

function Dashboard({
  usuario,
  school,
  docente,
  autoridades,
  delegados,
  capacitadores,
  prensa,
  coordi,
  schoolPendiente,
  docentePendiente,
  setSchoolsOutdated,
  setDocentesOutdated,
}) {
  const [elegido, setElegido] = useState('');
  const [totalSchools, setTotalSchools] = useState(0);
  const [totalDocentes, setTotalDocentes] = useState(0);
  const [totalDelegados, setTotalDelegados] = useState(0);
  const [totalAutoridades, setTotalAutoridades] = useState(0);
  const [totalCapacitadores, setTotalCapacitadores] = useState(0);
  const [totalPrensa, setTotalPrensa] = useState(0);
  const [totalCoordi, setTotalCoordi] = useState(0);
  const navigate = useNavigate();

  const [searchId, setSearchId] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
  }

  const generatePDF = result => {
    const doc = new jsPDF();

    doc.setFontSize(10);
    doc.setFont('helvetica');

    const text = 'AUTORIZACIÓN PARTICIPANTES';
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize();
    const centerX = (doc.internal.pageSize.width - textWidth) / 2;

    // Add text to the PDF
    doc.setFont('helvetica', 'bold');
    doc.text(`ID: ${result.id}`, 10, 5);
    doc.text(text, centerX, 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      'En mi carácter de padre/madre/tutor/representante (tachar lo que no corresponda) (en adelante,',
      centerX,
      20
    );
    doc.text(
      `el “Autorizante”) de ${result.nombre} ${result.apellido} DNI: ${result.dni} (en adelante, el/la "Participante"), por medio`,
      centerX,
      25
    );
    doc.text('de la presente declaro que:', centerX, 30);
    doc.text(
      '1. Autorizo expresamente al Participante a participar en las actividades desarrolladas por Asociación',
      centerX,
      40
    );
    doc.text(
      'Conciencia (en adelante, indistintamente “Conciencia”) para el Programa Educativo "Uniendo Metas',
      centerX,
      45
    );
    doc.text(
      'Bragado” (en adelante, el “Programa") a realizarse en la localidad de Bragado',
      centerX,
      50
    );
    doc.text(
      '2. Autorizo expresamente a Conciencia y a sus colaboradores a recolectar, utilizar y ceder todos los datos',
      centerX,
      60
    );
    doc.text(
      'personales, imágenes, testimonios, fotografías, videos y material gráfico y/o audiovisual del Participante,',
      centerX,
      65
    );
    doc.text(
      '(en adelante, indistintamente, los “Datos”) que fueran recolectados en el marco del Programa. Estos',
      centerX,
      70
    );
    doc.text(
      'Datos podrán ser editados y cedidos sin necesidad de autorización adicional, con finalidades relacionadas',
      centerX,
      75
    );
    doc.text(
      ' a la promoción y divulgación del Programa a través de cualquier medio de comunicación nacional e',
      centerX,
      80
    );
    doc.text(
      'internacional y sin derecho a recibir compensación alguna por ello.',
      centerX,
      85
    );

    doc.text(
      '3. He sido debidamente informado del alcance de la presente autorización, declarando no tener nada que',
      centerX,
      95
    );
    doc.text(
      'reclamar a Conciencia o a sus colaboradores por ningún concepto directo o indirecto relacionado con el',
      centerX,
      100
    );
    doc.text(
      'uso de los Datos del Participante en el Programa, que se pudieran suscitar como consecuencia y/o en',
      centerX,
      105
    );
    doc.text('ocasión de su participación en el Programa.', centerX, 110);

    doc.text(
      '4. He sido debidamente informado de que los Datos Personales seran recolectados en cumplimiento a la',
      centerX,
      120
    );
    doc.text(
      'Legislacion de Privacidad y seran incorporados en la base de datos de Conciencia ,ante quien podre',
      centerX,
      125
    );
    doc.text(
      'ejercer mi derecho de acceso. Asimismo, se me ha informado que (i) El titular de los datos personales',
      centerX,
      130
    );
    doc.text(
      'tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores',
      centerX,
      135
    );
    doc.text(
      'a seis meses, salvo que se acredite un interes legitimo al efecto, conforme lo establecido en el articulo 14,',
      centerX,
      140
    );
    doc.text(
      'inciso 3 de la Ley N° 25.326. (ii) El titular de los Datos personales podra en cualquier momento solicitar',
      centerX,
      145
    );
    doc.text(
      'el retiro o bloqueo de su nombre de los bancos de datos de Conciencia. (iii) El titular de los datos podra',
      centerX,
      150
    );
    doc.text(
      'ejercer el derecho de acceso, rectificacion, actualizacion, y supresion en forma gratuita en Tucuman 731,',
      centerX,
      155
    );
    doc.text(
      'piso 1, oficina "A", Ciudad Autonoma de Buenos Aires o enviando un correo electronico a',
      centerX,
      160
    );
    doc.text(
      'uniendometas@conciencia.org acreditando su identidad. (iv) La Agencia de Accesos a la informacion',
      centerX,
      165
    );
    doc.text(
      'Publica, en su caracter de Organo de Control de la Ley N° 25.326, tiene la atribucion de atender las',
      centerX,
      170
    );
    doc.text(
      'denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento',
      centerX,
      175
    );
    doc.text(
      'de las normas vigentes en materia de proteccion de datos personales. (v) Todos los Datos que sean',
      centerX,
      180
    );
    doc.text(
      'recolectados con finalidades distintas a las mencionadas seran en virtud de la relacion existente entre el',
      centerX,
      185
    );
    doc.text(
      'titular de los Datos y Conciencia conforme las excepciones establecidas en el inciso 2, literales c) y d) del',
      centerX,
      190
    );
    doc.text('articulo 5 de la Ley 25.326.', centerX, 195);

    doc.text(
      'Firma del Autorizante ____________________________',
      centerX,
      210
    );
    doc.text('Aclaracion: ____________________________________', centerX, 215);
    doc.text('DNI: __________________________________________', centerX, 220);
    doc.text(
      'Relacion con el Participante: _____________________',
      centerX,
      225
    );
    doc.text('Lugar y fecha: _________________________________', centerX, 230);
    doc.text('_______________________________________________', centerX, 245);
    doc.text(
      'Firma y sello del director del establecimiento educativo',
      centerX,
      250
    );

    doc.setFont('helvetica', 'bold');
    doc.text('CERTIFICACION MEDICA', centerX, 260);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `Certifico que ${result.nombre} ${result.apellido} de ____ años ha sido analizado clinicamente y se encuentra`,
      centerX,
      270
    );
    doc.text(
      'apto/a para participar en las actividades del Programa "Uniendo Metas Bragado".',
      centerX,
      275
    );

    doc.text('Firma del Medico ____________________________', centerX, 290);

    // Add more text and formatting based on your requirements
    // Example: doc.text('Participant Name: ' + result.participantName, 20, 30);

    var pdfDataUri = doc.output('dataurlstring');

    // Convert data URL string to Blob
    fetch(pdfDataUri)
      .then(response => response.blob())
      .then(blob => {
        // Create a temporary anchor element
        var downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = 'AUTORIZACION_PARTICIPANTES.pdf';

        // Trigger a click event on the anchor element
        var event = new MouseEvent('click');
        downloadLink.dispatchEvent(event);
      });
  };

  const handleSelectChange = selectedValue => {
    setSelectedOption(selectedValue);
  };

  const handleSearch = () => {
    if (selectedOption === 'delegados') {
      const result = delegados.find(
        delegado => delegado.id === parseInt(searchId)
      );
      setSearchResult(result);
      console.log(result);
      console.log(delegados);
    } else if (selectedOption === 'prensa') {
      const result = prensa.find(prensa => prensa.id === parseInt(searchId));
      setSearchResult(result);
      console.log(result);
    } else if (selectedOption === 'autoridades') {
      const result = autoridades.find(
        autoridad => autoridad.id === parseInt(searchId)
      );
      setSearchResult(result);
      console.log(result);
    } else if (selectedOption === 'coordi') {
      const result = coordi.find(coordi => coordi.id === parseInt(searchId));
      setSearchResult(result);
      console.log(result);
    }
  };

  if (usuario === null) {
    navigate('/admin');
  }

  useEffect(() => {
    setTotalSchools(school.length);
    setTotalDocentes(docente.length);
    setTotalAutoridades(autoridades.length);
    setTotalDelegados(delegados.length);
    setTotalCapacitadores(capacitadores.length);
    setTotalCoordi(coordi.length);
    setTotalPrensa(prensa.length);
  }, [school, docente, autoridades, delegados, capacitadores, coordi, prensa]);

  const {
    isOpen: isAproveEscuelaOpen,
    onOpen: onAproveEscuelaOpen,
    onClose: onAproveEscuelaClose,
  } = useDisclosure();

  const {
    isOpen: isAproveDocenteOpen,
    onOpen: onAproveDocenteOpen,
    onClose: onAproveDocenteClose,
  } = useDisclosure();

  const handleEscuela = async () => {
    const data = {
      nombre: elegido.nombre,
      cue: elegido.cue,
      telefono: elegido.telefono,
      email: elegido.email,
      localidad: elegido.localidad,
      autoridad: elegido.autoridad,
      provincia: elegido.provincia,
    };

    const result = await insertEscuelas(data);
    if (result.error === null) {
      onAproveEscuelaClose();
      setSchoolsOutdated(true);
      deleteEscuelaPendientes(elegido);
      setElegido('');
    }
  };

  const handleDocente = async () => {
    const data = {
      nombre_apellido: elegido.nombre_apellido,
      escuela: elegido.escuela,
      telefono: elegido.telefono,
      email: elegido.email,
      dni: elegido.dni,
      fechaNacimiento: elegido.fechaNacimiento,
      sanguineo: elegido.sanguineo,
      factor: elegido.factor,
      obra: elegido.obra,
      numero: elegido.numero,
      contacto_emergencia: elegido.contacto_emergencia,
      parentesco: elegido.parentesco,
      tel: elegido.tel,
      medico: elegido.medico,
      telmedico: elegido.telmedico,
      derivar: elegido.derivar,
      enfermedadref: elegido.enfermedadref,
      enfermedad: elegido.enfermedad,
      medicamentoref: elegido.medicamentoref,
      medicamento: elegido.medicamento,
      antecedentesref: elegido.antecedentesref,
      antecedentes: elegido.antecedentes,
      alergiasref: elegido.alergiasref,
      alergias: elegido.alergias,
      dietaref: elegido.dietaref,
      dieta: elegido.dieta,
      user_id: elegido.user_id,
    };

    const result = await insertDocentes(data);
    if (result.error === null) {
      onAproveDocenteClose();
      setDocentesOutdated(true);
      deleteDocentesPendientes(elegido);
      setElegido('');
    }
  };

  return (
    <>
      {usuario ? (
        <>
          <Box
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            mt={5}
          >
            <Tabs
              size="lg"
              variant="enclosed"
              align={'center'}
              w={{ base: '95vw', md: '70vw' }}
            >
              <Card maxW={'md'} display={'flex'} shadow={'light'} mt={6} mb={6}>
                <CardBody
                  bg={'#1a202c'}
                  borderRadius={5}
                  pt={2}
                  pb={2}
                  pl={4}
                  pr={4}
                >
                  <Stack divider={<StackDivider />} spacing={1}>
                    <Box>
                      <Heading
                        size="sm"
                        fontSize="20px"
                        textTransform="uppercase"
                        color={'white'}
                      >
                        escuelas
                      </Heading>

                      <Text
                        bgColor={'#63b3ed'}
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalSchools}
                      </Text>
                    </Box>
                    <Box>
                      <Heading
                        color={'white'}
                        fontSize="20px"
                        size="sm"
                        textTransform="uppercase"
                      >
                        docentes
                      </Heading>

                      <Text
                        bgColor={'#63b3ed'}
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalDocentes}
                      </Text>
                    </Box>
                    <Box>
                      <Heading
                        color={'white'}
                        fontSize="20px"
                        size="sm"
                        textTransform="uppercase"
                      >
                        delegados/as
                      </Heading>

                      <Text
                        bgColor={'#63b3ed'}
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalDelegados}
                      </Text>
                    </Box>
                    <Box>
                      <HStack justifyContent={'center'}>
                        <Heading
                          color={'white'}
                          fontSize={{ base: '14px', md: '20px' }}
                          size="sm"
                          textTransform="uppercase"
                        >
                          autoridades
                        </Heading>

                        <Text
                          bgColor="#63b3ed"
                          bgClip={'text'}
                          fontSize={{ base: '14px', md: '20px' }}
                          as={'b'}
                        >
                          {totalAutoridades}
                        </Text>
                        <Heading
                          color={'white'}
                          fontSize={{ base: '14px', md: '20px' }}
                          size="sm"
                          textTransform="uppercase"
                        >
                          Prensa
                        </Heading>

                        <Text
                          bgColor="#63b3ed"
                          bgClip={'text'}
                          fontSize={{ base: '14px', md: '20px' }}
                          as={'b'}
                        >
                          {totalPrensa}
                        </Text>
                        <Heading
                          color={'white'}
                          fontSize={{ base: '14px', md: '20px' }}
                          size="sm"
                          textTransform="uppercase"
                        >
                          Coordi
                        </Heading>

                        <Text
                          bgColor="#63b3ed"
                          bgClip={'text'}
                          fontSize={{ base: '14px', md: '20px' }}
                          as={'b'}
                        >
                          {totalCoordi}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      <Heading
                        color={'white'}
                        fontSize="20px"
                        size="sm"
                        textTransform="uppercase"
                      >
                        capacitadores/as
                      </Heading>

                      <Text
                        bgColor="#63b3ed"
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalCapacitadores}
                      </Text>
                    </Box>
                  </Stack>
                </CardBody>
              </Card>
              <TabList>
                <Tab
                  fontSize={{ base: 'sm', md: 'lg' }}
                  w={{ base: '104px', md: 'auto' }}
                  fontWeight={'200'}
                  p={{ base: 1, sm: '16px' }}
                >
                  Escuelas pendientes
                </Tab>
                <Tab
                  fontSize={{ base: 'sm', md: 'lg' }}
                  w={{ base: '104px', md: 'auto' }}
                  fontWeight={'200'}
                  p={{ base: 1, sm: '16px' }}
                >
                  Docentes pendientes
                </Tab>
                <Tab
                  fontSize={{ base: 'sm', md: 'lg' }}
                  w={{ base: '104px', md: 'auto' }}
                  fontWeight={'200'}
                  p={{ base: 1, sm: '16px' }}
                >
                  Escuelas aprobadas
                </Tab>
                <Tab
                  fontSize={{ base: 'sm', md: 'lg' }}
                  w={{ base: '104px', md: 'auto' }}
                  fontWeight={'200'}
                  p={{ base: 1, sm: '16px' }}
                >
                  Docentes aprobados
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>Nombre</Th>
                          <Th textAlign={'center'}>Telefono</Th>
                          <Th textAlign={'center'}>Email</Th>
                          <Th textAlign={'center'}>Localidad</Th>
                          <Th textAlign={'center'}>Provincia</Th>
                          <Th textAlign={'center'}>Autoridad</Th>
                          <Th textAlign={'center'}>Acciones</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {schoolPendiente?.map(escuela => (
                          <Tr key={escuela.id}>
                            <Td textAlign={'center'}>{escuela.nombre}</Td>
                            <Td textAlign={'center'}>{escuela.telefono}</Td>
                            <Td textAlign={'center'}>{escuela.email}</Td>
                            <Td textAlign={'center'}>{escuela.localidad}</Td>
                            <Td textAlign={'center'}>{escuela.provincia}</Td>
                            <Td textAlign={'center'}>{escuela.autoridad}</Td>
                            <Td display={'flex'} justifyContent={'center'}>
                              <Button
                                fontWeight={'700'}
                                boxShadow={'lg'}
                                size={{
                                  base: 'xs',
                                  md: 'md',
                                }}
                                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                                _hover={{
                                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                                }}
                                onClick={() => {
                                  setElegido(escuela);
                                  onAproveEscuelaOpen();
                                }}
                              >
                                <Tooltip
                                  hasArrow
                                  label="Aprobar"
                                  bg={'#181718'}
                                  color="white"
                                  fontWeight={'bold'}
                                  fontSize={'md'}
                                >
                                  <CheckIcon />
                                </Tooltip>
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>Nombre y Apellido</Th>
                          <Th textAlign={'center'}>Escuela</Th>
                          <Th textAlign={'center'}>Telefono</Th>
                          <Th textAlign={'center'}>Email</Th>
                          <Th textAlign={'center'}>Acciones</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {docentePendiente?.map(docente => (
                          <Tr key={docente.id}>
                            <Td textAlign={'center'}>
                              {docente.nombre_apellido}
                            </Td>
                            <Td textAlign={'center'}>{docente.escuela}</Td>
                            <Td textAlign={'center'}>{docente.telefono}</Td>
                            <Td textAlign={'center'}>{docente.email}</Td>

                            <Td display={'flex'} justifyContent={'center'}>
                              <Button
                                fontWeight={'700'}
                                boxShadow={'lg'}
                                size={{
                                  base: 'xs',
                                  md: 'md',
                                }}
                                bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                                _hover={{
                                  bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                                }}
                                onClick={() => {
                                  setElegido(docente);
                                  onAproveDocenteOpen();
                                }}
                              >
                                <Tooltip
                                  hasArrow
                                  label="Aprobar"
                                  bg={'#181718'}
                                  color="white"
                                  fontWeight={'bold'}
                                  fontSize={'md'}
                                >
                                  <CheckIcon />
                                </Tooltip>
                              </Button>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>Nombre</Th>
                          <Th textAlign={'center'}>Telefono</Th>
                          <Th textAlign={'center'}>Email</Th>
                          <Th textAlign={'center'}>Localidad</Th>
                          <Th textAlign={'center'}>Provincia</Th>
                          <Th textAlign={'center'}>Autoridad</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {school?.map(escuela => (
                          <Tr key={escuela.id}>
                            <Td textAlign={'center'}>{escuela.nombre}</Td>
                            <Td textAlign={'center'}>{escuela.telefono}</Td>
                            <Td textAlign={'center'}>{escuela.email}</Td>
                            <Td textAlign={'center'}>{escuela.localidad}</Td>
                            <Td textAlign={'center'}>{escuela.provincia}</Td>
                            <Td textAlign={'center'}>{escuela.autoridad}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel>
                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th textAlign={'center'}>Nombre y Apellido</Th>
                          <Th textAlign={'center'}>Escuela</Th>
                          <Th textAlign={'center'}>Telefono</Th>
                          <Th textAlign={'center'}>Email</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {docente?.map(docente => (
                          <Tr key={docente.id}>
                            <Td textAlign={'center'}>
                              {docente.nombre_apellido}
                            </Td>
                            <Td textAlign={'center'}>{docente.escuela}</Td>
                            <Td textAlign={'center'}>{docente.telefono}</Td>
                            <Td textAlign={'center'}>{docente.email}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Modal
              size={{ base: 'xs', md: 'md' }}
              isOpen={isAproveEscuelaOpen}
              onClose={onAproveEscuelaClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontWeight={'700'}>Aprobar escuela</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={2}>
                    <Text mb={'1rem'}>
                      ¿Estas seguro/a que{' '}
                      <Text as={'b'}>revisaste todo y esta correctamente?</Text>{' '}
                      Si es asi, presiona el boton de aprobar.
                    </Text>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    size={{ base: 'sm', md: 'md' }}
                    fontWeight={'700'}
                    mr={2}
                    bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                    _hover={{
                      bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                    }}
                    onClick={() => {
                      handleEscuela();
                    }}
                  >
                    <Tooltip
                      hasArrow
                      label="Aprobar"
                      bg={'#181718'}
                      color="white"
                      fontWeight={'bold'}
                      fontSize={'md'}
                    >
                      <CheckIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    size={{ base: 'sm', md: 'md' }}
                    fontWeight={'700'}
                    onClick={() => {
                      onAproveEscuelaClose();
                    }}
                    bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                    _hover={{
                      bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                    }}
                  >
                    <Tooltip
                      hasArrow
                      label="Volver"
                      bg={'#181718'}
                      color="white"
                      fontWeight={'bold'}
                      fontSize={'md'}
                    >
                      <ArrowBackIcon />
                    </Tooltip>
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>

            <Modal
              size={{ base: 'xs', md: 'md' }}
              isOpen={isAproveDocenteOpen}
              onClose={onAproveDocenteClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader fontWeight={'700'}>Aprobar docente</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing={2}>
                    <Text mb={'1rem'}>
                      ¿Estas seguro/a que{' '}
                      <Text as={'b'}>revisaste todo y esta correctamente?</Text>{' '}
                      Si es asi, presiona el boton de aprobar.
                    </Text>
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button
                    size={{ base: 'sm', md: 'md' }}
                    fontWeight={'700'}
                    mr={2}
                    bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                    _hover={{
                      bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                    }}
                    onClick={() => {
                      handleDocente();
                    }}
                  >
                    <Tooltip
                      hasArrow
                      label="Aprobar"
                      bg={'#181718'}
                      color="white"
                      fontWeight={'bold'}
                      fontSize={'md'}
                    >
                      <CheckIcon />
                    </Tooltip>
                  </Button>
                  <Button
                    size={{ base: 'sm', md: 'md' }}
                    fontWeight={'700'}
                    onClick={() => {
                      onAproveEscuelaClose();
                    }}
                    bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                    _hover={{
                      bgGradient: 'linear(to-l, #233D72, #1E2F48)',
                    }}
                  >
                    <Tooltip
                      hasArrow
                      label="Volver"
                      bg={'#181718'}
                      color="white"
                      fontWeight={'bold'}
                      fontSize={'md'}
                    >
                      <ArrowBackIcon />
                    </Tooltip>
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
          <>
            <Box
              mt={5}
              w={{ base: '95vw', md: '70vw' }}
              mx="auto" // Add this line to center horizontally
              display="flex" // Add this line to make it a flex container
              justifyContent="center" // Add this line to center the content horizontally
            >
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  placeholder="Enter ID"
                  value={searchId}
                  onChange={e => setSearchId(e.target.value)}
                />
                <InputRightElement width="15em">
                  <Select
                    placeholder="Select"
                    size="md"
                    mr={2}
                    onChange={e => handleSelectChange(e.target.value)}
                  >
                    <option value="delegados">Delegados</option>
                    <option value="prensa">Prensa</option>
                    <option value="autoridades">Autoridades</option>
                    <option value="coordi">Coordi</option>
                  </Select>
                  <Button
                    mr={2}
                    h="1.75rem"
                    size="sm"
                    onClick={() => handleSearch()}
                  >
                    Buscar
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Box>

            {searchResult && (
              <Box
                display={'flex'}
                justifyContent="center"
                alignItems="center"
                mt={5}
              >
                <Tabs
                  size={{ base: 'sm', md: 'lg' }}
                  variant="enclosed"
                  align={'center'}
                  w={{ base: '95vw', md: '70vw' }}
                >
                  <TabList>
                    <Tab
                      fontSize={{ base: 'sm', md: 'lg' }}
                      w={{ base: '104px', md: 'auto' }}
                      fontWeight={'200'}
                      p={{ base: 1, sm: '16px' }}
                    >
                      Busqueda
                    </Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <TableContainer>
                        <Table variant="simple" size={{ base: 'sm' }}>
                          <Thead>
                            <Tr>
                              <Th textAlign={'center'}>Nombre</Th>
                              <Th textAlign={'center'}>Apellido</Th>
                              <Th textAlign={'center'}>Telefono</Th>
                              <Th textAlign={'center'}>Email</Th>
                              <Th textAlign={'center'}>PDF</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            <Tr key={searchResult.id}>
                              <Td textAlign={'center'}>
                                {searchResult.nombre}
                              </Td>
                              <Td textAlign={'center'}>
                                {searchResult.apellido}
                              </Td>
                              <Td textAlign={'center'}>
                                {searchResult.telefono}
                              </Td>
                              <Td textAlign={'center'}>{searchResult.email}</Td>

                              <Td display={'flex'} justifyContent={'center'}>
                                <Button
                                  fontWeight={'700'}
                                  boxShadow={'lg'}
                                  size={{
                                    base: 'xs',
                                    md: 'md',
                                  }}
                                  bgGradient={'linear(to-r, #233D72, #1E2F48)'}
                                  _hover={{
                                    bgGradient:
                                      'linear(to-l, #233D72, #1E2F48)',
                                  }}
                                  onClick={() => {
                                    generatePDF(searchResult);
                                  }}
                                >
                                  <Tooltip
                                    hasArrow
                                    label="GENERAR PDF"
                                    bg={'#181718'}
                                    color="white"
                                    fontWeight={'bold'}
                                    fontSize={'md'}
                                  >
                                    <CheckIcon />
                                  </Tooltip>
                                </Button>
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
          </>
        </>
      ) : null}
    </>
  );
}
export default Dashboard;
