import {
  Table,
  Box,
  Button,
  Tooltip,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Card,
  CardBody,
  StackDivider,
  Heading,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  ModalContent,
  ModalHeader,
  Stack,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  HStack,
} from '@chakra-ui/react';
import { CheckIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function DashboardDocentes({
  usuario,
  school,
  docente,
  autoridades,
  delegados,
  capacitadores,
  prensa,
  coordi,
}) {
  const [totalDelegados, setTotalDelegados] = useState(0);
  const [totalAutoridades, setTotalAutoridades] = useState(0);
  const [totalPrensa, setTotalPrensa] = useState(0);
  const [totalCoordi, setTotalCoordi] = useState(0);
  const navigate = useNavigate();

  const lista_delegados = delegados.filter(
    delegado =>
      delegado.docente ===
      docente.filter(docente => docente.email === usuario?.email)[0]
        ?.nombre_apellido
  );
  const lista_autoridades = autoridades.filter(
    autoridad =>
      autoridad.docente ===
      docente.filter(docente => docente.email === usuario?.email)[0]
        ?.nombre_apellido
  );
  const lista_prensa = prensa.filter(
    prensa =>
      prensa.docente ===
      docente.filter(docente => docente.email === usuario?.email)[0]
        ?.nombre_apellido
  );
  const lista_coordi = coordi.filter(
    coordi =>
      coordi.docente ===
      docente.filter(docente => docente.email === usuario?.email)[0]
        ?.nombre_apellido
  );

  if (usuario === null) {
    navigate('/ingreso-docentes');
  }

  useEffect(() => {
    setTotalAutoridades(autoridades.length);
    setTotalDelegados(delegados.length);
    setTotalCoordi(coordi.length);
    setTotalPrensa(prensa.length);
  }, [school, docente, autoridades, delegados, capacitadores, coordi, prensa]);

  return (
    <>
      {usuario ? (
        <Box
          display={'flex'}
          justifyContent="center"
          alignItems="center"
          mt={5}
        >
          <Tabs
            size="lg"
            variant="enclosed"
            align={'center'}
            w={{ base: '95vw', md: '70vw' }}
          >
            <Card maxW={'md'} display={'flex'} shadow={'light'} mt={6} mb={6}>
              <CardBody
                bg={'#1a202c'}
                borderRadius={5}
                pt={2}
                pb={2}
                pl={4}
                pr={4}
              >
                <Stack divider={<StackDivider />} spacing={1}>
                  <Box>
                    <Heading
                      color={'white'}
                      fontSize="20px"
                      size="sm"
                      textTransform="uppercase"
                    >
                      delegados/as
                    </Heading>

                    <Text
                      bgColor={'#63b3ed'}
                      bgClip={'text'}
                      fontSize="22px"
                      as={'b'}
                    >
                      {totalDelegados}
                    </Text>
                  </Box>
                  <Box>
                    <HStack>
                      <Heading
                        color={'white'}
                        fontSize="20px"
                        size="sm"
                        textTransform="uppercase"
                      >
                        autoridades
                      </Heading>

                      <Text
                        bgColor="#63b3ed"
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalAutoridades}
                      </Text>
                      <Heading
                        color={'white'}
                        fontSize="20px"
                        size="sm"
                        textTransform="uppercase"
                      >
                        Prensa
                      </Heading>

                      <Text
                        bgColor="#63b3ed"
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalPrensa}
                      </Text>
                      <Heading
                        color={'white'}
                        fontSize="20px"
                        size="sm"
                        textTransform="uppercase"
                      >
                        Coordi
                      </Heading>

                      <Text
                        bgColor="#63b3ed"
                        bgClip={'text'}
                        fontSize="22px"
                        as={'b'}
                      >
                        {totalCoordi}
                      </Text>
                    </HStack>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
            <Button
              mb={4}
              w={{ base: '314px', md: '505px' }}
              borderRadius="2rem"
              marginLeft={{ base: 0, sm: 0 }}
              fontSize={'xl'}
              fontWeight="700"
              loadingText="Submitting"
              bgGradient={'linear(to-r, #233D72, #1E2F48)'}
              color={'#FFFFFF'}
              _hover={{
                bgGradient: 'linear(to-tl, #233D72, #1E2F48)',
              }}
              onClick={() => navigate('/inscripciones/docentes')}
            >
              Registrarse en una nueva escuela
            </Button>
            <TabList>
              <Tab
                fontSize={{ base: 'sm', md: 'lg' }}
                w={{ base: '104px', md: 'auto' }}
                fontWeight={'200'}
                p={{ base: 1, sm: '16px' }}
              >
                Delegados/as
              </Tab>
              <Tab
                fontSize={{ base: 'sm', md: 'lg' }}
                w={{ base: '104px', md: 'auto' }}
                fontWeight={'200'}
                p={{ base: 1, sm: '16px' }}
              >
                Autoridades
              </Tab>
              <Tab
                fontSize={{ base: 'sm', md: 'lg' }}
                w={{ base: '104px', md: 'auto' }}
                fontWeight={'200'}
                p={{ base: 1, sm: '16px' }}
              >
                Prensa
              </Tab>
              <Tab
                fontSize={{ base: 'sm', md: 'lg' }}
                w={{ base: '104px', md: 'auto' }}
                fontWeight={'200'}
                p={{ base: 1, sm: '16px' }}
              >
                Coordinacion
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th textAlign={'center'}>ID</Th>
                        <Th textAlign={'center'}>Nombre</Th>
                        <Th textAlign={'center'}>Apellido</Th>
                        <Th textAlign={'center'}>Telefono</Th>
                        <Th textAlign={'center'}>Email</Th>
                        <Th textAlign={'center'}>DNI</Th>
                        <Th textAlign={'center'}>Fecha de nacimiento</Th>
                        <Th textAlign={'center'}>Escuela</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {lista_delegados?.map(delegado => (
                        <Tr key={delegado.id}>
                          <Td textAlign={'center'}>{delegado.id}</Td>
                          <Td textAlign={'center'}>{delegado.nombre}</Td>
                          <Td textAlign={'center'}>{delegado.apellido}</Td>
                          <Td textAlign={'center'}>{delegado.telefono}</Td>
                          <Td textAlign={'center'}>{delegado.email}</Td>
                          <Td textAlign={'center'}>{delegado.dni}</Td>
                          <Td textAlign={'center'}>
                            {delegado.fechanacimiento}
                          </Td>
                          <Td textAlign={'center'}>{delegado.escuela}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th textAlign={'center'}>ID</Th>
                        <Th textAlign={'center'}>Nombre</Th>
                        <Th textAlign={'center'}>Apellido</Th>
                        <Th textAlign={'center'}>Telefono</Th>
                        <Th textAlign={'center'}>Email</Th>
                        <Th textAlign={'center'}>DNI</Th>
                        <Th textAlign={'center'}>Fecha de nacimiento</Th>
                        <Th textAlign={'center'}>Escuela</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {lista_autoridades?.map(autoridad => (
                        <Tr key={autoridad.id}>
                          <Td textAlign={'center'}>{autoridad.id}</Td>
                          <Td textAlign={'center'}>{autoridad.nombre}</Td>
                          <Td textAlign={'center'}>{autoridad.apellido}</Td>
                          <Td textAlign={'center'}>{autoridad.telefono}</Td>
                          <Td textAlign={'center'}>{autoridad.email}</Td>
                          <Td textAlign={'center'}>{autoridad.dni}</Td>
                          <Td textAlign={'center'}>
                            {autoridad.fechanacimiento}
                          </Td>
                          <Td textAlign={'center'}>{autoridad.escuela}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th textAlign={'center'}>ID</Th>
                        <Th textAlign={'center'}>Nombre</Th>
                        <Th textAlign={'center'}>Apellido</Th>
                        <Th textAlign={'center'}>Telefono</Th>
                        <Th textAlign={'center'}>Email</Th>
                        <Th textAlign={'center'}>DNI</Th>
                        <Th textAlign={'center'}>Fecha de nacimiento</Th>
                        <Th textAlign={'center'}>Escuela</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {lista_prensa?.map(prensa => (
                        <Tr key={prensa.id}>
                          <Td textAlign={'center'}>{prensa.id}</Td>
                          <Td textAlign={'center'}>{prensa.nombre}</Td>
                          <Td textAlign={'center'}>{prensa.apellido}</Td>
                          <Td textAlign={'center'}>{prensa.telefono}</Td>
                          <Td textAlign={'center'}>{prensa.email}</Td>
                          <Td textAlign={'center'}>{prensa.dni}</Td>
                          <Td textAlign={'center'}>{prensa.fechanacimiento}</Td>
                          <Td textAlign={'center'}>{prensa.escuela}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
              <TabPanel>
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th textAlign={'center'}>ID</Th>
                        <Th textAlign={'center'}>Nombre</Th>
                        <Th textAlign={'center'}>Apellido</Th>
                        <Th textAlign={'center'}>Telefono</Th>
                        <Th textAlign={'center'}>Email</Th>
                        <Th textAlign={'center'}>DNI</Th>
                        <Th textAlign={'center'}>Fecha de nacimiento</Th>
                        <Th textAlign={'center'}>Escuela</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {lista_coordi?.map(coordi => (
                        <Tr key={coordi.id}>
                          <Td textAlign={'center'}>{coordi.id}</Td>
                          <Td textAlign={'center'}>{coordi.nombre}</Td>
                          <Td textAlign={'center'}>{coordi.apellido}</Td>
                          <Td textAlign={'center'}>{coordi.telefono}</Td>
                          <Td textAlign={'center'}>{coordi.email}</Td>
                          <Td textAlign={'center'}>{coordi.dni}</Td>
                          <Td textAlign={'center'}>{coordi.fechanacimiento}</Td>
                          <Td textAlign={'center'}>{coordi.escuela}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      ) : null}
    </>
  );
}
export default DashboardDocentes;
