import {
  Text,
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  HStack,
  Accordion,
  AccordionButton,
  AccordionItem,
  Flex,
  AccordionPanel,
  Radio,
  RadioGroup,
  FormErrorMessage,
} from '@chakra-ui/react';

import useForm from '../hooks/useForm';
import { useState } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { insertDelegados } from '../services/supabase';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';

const initialState = {
  nombreRef: '',
  apellidoRef: '',
  phoneRef: '',
  emailRef: '',
  dniRef: '',
  fechaRef: '',
  sanguineoRef: '',
  factorRef: '',
  obraRef: '',
  numeroRef: '',
  contactoRef: '',
  parentescoRef: '',
  telRef: '',
  medicoRef: '',
  telmedicoRef: '',
  derivarRef: '',
  enfermedadRef: '',
  medicamentoRef: '',
  antecedentesRef: '',
  alergiasRef: '',
  dietaRef: '',
};

function InscripcionDelegados({
  school,
  docente,
  setSchoolsOutdated,
  setDocentesOutdated,
  setUserOutdated,
}) {
  const { formValues, handleInputChange, reset } = useForm(initialState);

  const [selectedEscuela, setSelectedEscuela] = useState('');
  const [selectedDocente, setSelectedDocente] = useState('');
  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const [isSectionOpen2, setIsSectionOpen2] = useState(true);
  const [acerbrag, setAcerbrag] = useState('');
  const [enfermedad, setEnfermedad] = useState('');
  const [medicamento, setMedicamento] = useState('');
  const [antecedentes, setAntecedentes] = useState('');
  const [alergias, setAlergias] = useState('');
  const [dieta, setDieta] = useState('');
  const [docentes, setDocentes] = useState([]);
  const [acerbragMessage, setAcerbragMessage] = useState('');
  const [enfermedadMessage, setEnfermedadMessage] = useState('');
  const [medicamentoMessage, setMedicamentoMessage] = useState('');
  const [antecedentesMessage, setAntecedentesMessage] = useState('');
  const [alergiasMessage, setAlergiasMessage] = useState('');
  const [dietaMessage, setDietaMessage] = useState('');
  const navigate = useNavigate();
  const handleSuccessNavigation = data => {
    navigate('success', { state: { delegadoData: data } });
  };

  function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'application/pdf' });
  }

  const generatePDF = result => {
    const doc = new jsPDF();

    doc.setFontSize(10);
    doc.setFont('helvetica');

    const text = 'AUTORIZACIÓN PARTICIPANTES';
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize();
    const centerX = (doc.internal.pageSize.width - textWidth) / 2;

    // Add text to the PDF
    doc.setFont('helvetica', 'bold');
    doc.text(`ID: ${result.id}`, 10, 5);
    doc.text(text, centerX, 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      'En mi carácter de padre/madre/tutor/representante (tachar lo que no corresponda) (en adelante,',
      centerX,
      20
    );
    doc.text(
      `el “Autorizante”) de ${result.nombre} ${result.apellido} DNI: ${result.dni} (en adelante, el/la "Participante"), por medio`,
      centerX,
      25
    );
    doc.text('de la presente declaro que:', centerX, 30);
    doc.text(
      '1. Autorizo expresamente al Participante a participar en las actividades desarrolladas por Asociación',
      centerX,
      40
    );
    doc.text(
      'Conciencia (en adelante, indistintamente “Conciencia”) para el Programa Educativo "Uniendo Metas',
      centerX,
      45
    );
    doc.text(
      'Bragado” (en adelante, el “Programa") a realizarse en la localidad de Bragado',
      centerX,
      50
    );
    doc.text(
      '2. Autorizo expresamente a Conciencia y a sus colaboradores a recolectar, utilizar y ceder todos los datos',
      centerX,
      60
    );
    doc.text(
      'personales, imágenes, testimonios, fotografías, videos y material gráfico y/o audiovisual del Participante,',
      centerX,
      65
    );
    doc.text(
      '(en adelante, indistintamente, los “Datos”) que fueran recolectados en el marco del Programa. Estos',
      centerX,
      70
    );
    doc.text(
      'Datos podrán ser editados y cedidos sin necesidad de autorización adicional, con finalidades relacionadas',
      centerX,
      75
    );
    doc.text(
      ' a la promoción y divulgación del Programa a través de cualquier medio de comunicación nacional e',
      centerX,
      80
    );
    doc.text(
      'internacional y sin derecho a recibir compensación alguna por ello.',
      centerX,
      85
    );

    doc.text(
      '3. He sido debidamente informado del alcance de la presente autorización, declarando no tener nada que',
      centerX,
      95
    );
    doc.text(
      'reclamar a Conciencia o a sus colaboradores por ningún concepto directo o indirecto relacionado con el',
      centerX,
      100
    );
    doc.text(
      'uso de los Datos del Participante en el Programa, que se pudieran suscitar como consecuencia y/o en',
      centerX,
      105
    );
    doc.text('ocasión de su participación en el Programa.', centerX, 110);

    doc.text(
      '4. He sido debidamente informado de que los Datos Personales seran recolectados en cumplimiento a la',
      centerX,
      120
    );
    doc.text(
      'Legislacion de Privacidad y seran incorporados en la base de datos de Conciencia ,ante quien podre',
      centerX,
      125
    );
    doc.text(
      'ejercer mi derecho de acceso. Asimismo, se me ha informado que (i) El titular de los datos personales',
      centerX,
      130
    );
    doc.text(
      'tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores',
      centerX,
      135
    );
    doc.text(
      'a seis meses, salvo que se acredite un interes legitimo al efecto, conforme lo establecido en el articulo 14,',
      centerX,
      140
    );
    doc.text(
      'inciso 3 de la Ley N° 25.326. (ii) El titular de los Datos personales podra en cualquier momento solicitar',
      centerX,
      145
    );
    doc.text(
      'el retiro o bloqueo de su nombre de los bancos de datos de Conciencia. (iii) El titular de los datos podra',
      centerX,
      150
    );
    doc.text(
      'ejercer el derecho de acceso, rectificacion, actualizacion, y supresion en forma gratuita en Tucuman 731,',
      centerX,
      155
    );
    doc.text(
      'piso 1, oficina "A", Ciudad Autonoma de Buenos Aires o enviando un correo electronico a',
      centerX,
      160
    );
    doc.text(
      'uniendometas@conciencia.org acreditando su identidad. (iv) La Agencia de Accesos a la informacion',
      centerX,
      165
    );
    doc.text(
      'Publica, en su caracter de Organo de Control de la Ley N° 25.326, tiene la atribucion de atender las',
      centerX,
      170
    );
    doc.text(
      'denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento',
      centerX,
      175
    );
    doc.text(
      'de las normas vigentes en materia de proteccion de datos personales. (v) Todos los Datos que sean',
      centerX,
      180
    );
    doc.text(
      'recolectados con finalidades distintas a las mencionadas seran en virtud de la relacion existente entre el',
      centerX,
      185
    );
    doc.text(
      'titular de los Datos y Conciencia conforme las excepciones establecidas en el inciso 2, literales c) y d) del',
      centerX,
      190
    );
    doc.text('articulo 5 de la Ley 25.326.', centerX, 195);

    doc.text(
      'Firma del Autorizante ____________________________',
      centerX,
      210
    );
    doc.text('Aclaracion: ____________________________________', centerX, 215);
    doc.text('DNI: __________________________________________', centerX, 220);
    doc.text(
      'Relacion con el Participante: _____________________',
      centerX,
      225
    );
    doc.text('Lugar y fecha: _________________________________', centerX, 230);
    doc.text('_______________________________________________', centerX, 245);
    doc.text(
      'Firma y sello del director del establecimiento educativo',
      centerX,
      250
    );

    doc.setFont('helvetica', 'bold');
    doc.text('CERTIFICACION MEDICA', centerX, 260);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `Certifico que ${result.nombre} ${result.apellido} de ____ años ha sido analizado clinicamente y se encuentra`,
      centerX,
      270
    );
    doc.text(
      'apto/a para participar en las actividades del Programa "Uniendo Metas Bragado".',
      centerX,
      275
    );

    doc.text('Firma del Medico ____________________________', centerX, 290);

    // Add more text and formatting based on your requirements
    // Example: doc.text('Participant Name: ' + result.participantName, 20, 30);

    var pdfDataUri = doc.output('datauristring');

    // Create a blob URL for the PDF data
    var blob = dataURItoBlob(pdfDataUri);
    var blobUrl = URL.createObjectURL(blob);

    // Create a download link and trigger a click on it
    var downloadLink = document.createElement('a');
    downloadLink.href = blobUrl;
    downloadLink.download = 'AUTORIZACION_PARTICIPANTES.pdf';
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up
    document.body.removeChild(downloadLink);
  };

  const handleSectionToggle = () => {
    setIsSectionOpen(!isSectionOpen);
  };

  const handleSectionToggle2 = () => {
    setIsSectionOpen2(!isSectionOpen2);
  };

  const handleAcerbragChange = value => {
    setAcerbrag(value);
    setAcerbragMessage('');
  };

  const handleEnfermedadChange = value => {
    setEnfermedad(value);
    setEnfermedadMessage('');
  };

  const handleMedicamentoChange = value => {
    setMedicamento(value);
    setMedicamentoMessage('');
  };

  const handleAntecedentesChange = value => {
    setAntecedentes(value);
    setAntecedentesMessage('');
  };

  const handleAlergiasChange = value => {
    setAlergias(value);
    setAlergiasMessage('');
  };

  const handleDietaChange = value => {
    setDieta(value);
    setDietaMessage('');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const {
      nombreRef,
      apellidoRef,
      phoneRef,
      emailRef,
      dniRef,
      fechaRef,
      sanguineoRef,
      factorRef,
      obraRef,
      numeroRef,
      contactoRef,
      parentescoRef,
      telRef,
      medicoRef,
      telmedicoRef,
      derivarRef,
      enfermedadRef,
      medicamentoRef,
      antecedentesRef,
      alergiasRef,
      dietaRef,
    } = formValues;

    const data = {
      nombre: nombreRef,
      apellido: apellidoRef,
      telefono: phoneRef,
      email: emailRef,
      dni: dniRef,
      fechanacimiento: fechaRef,
      sanguineo: sanguineoRef,
      factor: factorRef,
      obra: obraRef,
      numero: numeroRef,
      contacto_emergencia: contactoRef,
      parentesco: parentescoRef,
      tel: telRef,
      medico: medicoRef,
      telmedico: telmedicoRef,
      derivar: derivarRef,
      enfermedadref: enfermedadRef,
      enfermedad: enfermedad,
      medicamentoref: medicamentoRef,
      medicamento: medicamento,
      antecedentesref: antecedentesRef,
      antecedentes: antecedentes,
      alergiasref: alergiasRef,
      alergias: alergias,
      dietaref: dietaRef,
      dieta: dieta,
      escuela: selectedEscuela,
      docente: selectedDocente,
      acerbrag: acerbrag,
    };
    if (
      acerbrag !== '' &&
      enfermedad !== '' &&
      medicamento !== '' &&
      antecedentes !== '' &&
      alergias !== '' &&
      dieta !== '' &&
      selectedEscuela !== '' &&
      selectedDocente !== ''
    ) {
      const {
        error: errorDelegados,
        data: dataDelegados,
        status: statusDelegados,
      } = await insertDelegados(data);
      if (errorDelegados === null) {
        console.log(dataDelegados);
        generatePDF(dataDelegados[0]);
        setSchoolsOutdated(true);
        setDocentesOutdated(true);
        setUserOutdated(true);
        reset();
        handleSuccessNavigation(dataDelegados[0]);
      } else {
        console.log(errorDelegados);
      }
    } else {
      if (acerbrag === '') {
        setAcerbragMessage('Este campo es requerido');
      }
      if (enfermedad === '') {
        setEnfermedadMessage('Este campo es requerido');
      }
      if (medicamento === '') {
        setMedicamentoMessage('Este campo es requerido');
      }
      if (antecedentes === '') {
        setAntecedentesMessage('Este campo es requerido');
      }
      if (alergias === '') {
        setAlergiasMessage('Este campo es requerido');
      }
      if (dieta === '') {
        setDietaMessage('Este campo es requerido');
      }
    }
  };

  const handleEscuelaChange = event => {
    const escuela = event.target.value;
    setSelectedEscuela(escuela);

    const docentesFiltrados = docente.filter(
      docente => docente.escuela === escuela
    );
    setDocentes(docentesFiltrados);
  };

  const handleDocenteChange = event => {
    setSelectedDocente(event.target.value);
  };

  return (
    <>
      <Text
        /* color="white" */
        fontSize={{ base: '20px', md: '60px' }}
        fontWeight="black"
        textAlign={'center'}
        mt={4}
      >
        INSCRIPCIÓN DELEGADOS/AS
      </Text>
      <form onSubmit={handleSubmit}>
        <Accordion defaultIndex={[0, 1]} allowMultiple>
          <AccordionItem>
            <Stack
              spacing={2}
              mt={4}
              alignItems={{ base: 'center', sm: 'center' }}
            >
              <Box
                marginLeft={{ base: 2, sm: 0 }}
                w={{ base: 'auto', md: 'lg' }}
                borderRadius="lg"
              >
                <AccordionButton onClick={handleSectionToggle}>
                  <Box as="span" flex="1" textAlign="left">
                    <Flex alignItems="center">
                      <Text
                        fontSize={{ base: '18px', md: '30px' }}
                        fontWeight="black"
                        textAlign="left"
                        mb={4}
                      >
                        Datos Personales
                      </Text>
                      {isSectionOpen ? (
                        <ArrowUpIcon boxSize={6} mb={4} ml={2} />
                      ) : (
                        <ArrowDownIcon boxSize={6} mb={4} ml={2} />
                      )}
                    </Flex>
                  </Box>
                </AccordionButton>

                <AccordionPanel>
                  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  >
                    <FormControl
                      id="nombreRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        ml={{ base: 0, sm: 3 }}
                        display={'flex'}
                        fontWeight="700"
                        htmlFor="nombreRef"
                      >
                        Nombre
                      </FormLabel>
                      <Input
                        id="nombreRef"
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese su nombre..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="nombreRef"
                        value={formValues.nombreRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                    <FormControl
                      id="apellidoRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        /* ml={{ base: 0, sm: 3 }} */
                        display={'flex'}
                        fontWeight="700"
                      >
                        Apellido
                      </FormLabel>
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese su apellido..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        /* ml={{ base: 0, sm: '7px' }} */
                        mb={2}
                        type="text"
                        name="apellidoRef"
                        value={formValues.apellidoRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </HStack>
                  <FormControl
                    id="escuela"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Establecimiento Educativo
                    </FormLabel>
                    <Select
                      placeholder="Seleccione el establecimiento educativo..."
                      _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                      /* color={'#181718'} */
                      value={selectedEscuela}
                      /* bgColor={'white'} */
                      onChange={handleEscuelaChange}
                      fontSize={{ base: '12px', md: '16px' }}
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      borderRadius="1rem"
                      mb={2}
                      required
                    >
                      {school?.map(escuela => (
                        <option value={escuela.nombre}>{escuela.nombre}</option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    id="docente"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Docente a cargo
                    </FormLabel>
                    <Select
                      placeholder="Seleccione docente a cargo..."
                      _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                      /* color={'#181718'} */
                      value={selectedDocente}
                      /* bgColor={'white'} */
                      onChange={handleDocenteChange}
                      fontSize={{ base: '12px', md: '16px' }}
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      borderRadius="1rem"
                      mb={2}
                      required
                    >
                      {docentes?.map(docente => (
                        <option
                          key={docente.id}
                          value={docente.nombre_apellido}
                        >
                          {docente.nombre_apellido}
                        </option>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl
                    id="fechaRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Fecha de nacimiento
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese su fecha de nacimiento..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="date"
                      name="fechaRef"
                      value={formValues.fechaRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="phoneRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Telefono (Sin espacios ni guiones)
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el numero de telefono..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="phone"
                      name="phoneRef"
                      value={formValues.phoneRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="emailRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Email
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el email..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="email"
                      name="emailRef"
                      value={formValues.emailRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="dniRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      DNI (SIN ESPACIOS NI PUNTOS)
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese su DNI..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="dniRef"
                      value={formValues.dniRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="acerbragRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={acerbragMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Tiene algun pariente trabajando en Acerbrag? (Madre,
                      padre, hermanos/as)
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleAcerbragChange}
                      value={acerbrag}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {acerbragMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {acerbragMessage}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </AccordionPanel>
              </Box>
            </Stack>
          </AccordionItem>

          <AccordionItem>
            <Stack
              spacing={2}
              mt={4}
              alignItems={{ base: 'center', sm: 'center' }}
            >
              <Box
                marginLeft={{ base: 2, sm: 0 }}
                w={{ base: 'auto', md: 'lg' }}
                borderRadius="lg"
              >
                <AccordionButton onClick={handleSectionToggle2}>
                  <Box as="span" flex="1" textAlign="left">
                    <Flex alignItems="center">
                      <Text
                        fontSize={{ base: '18px', md: '30px' }}
                        fontWeight="black"
                        textAlign="left"
                        mb={4}
                      >
                        Datos Médicos
                      </Text>
                      {isSectionOpen2 ? (
                        <ArrowUpIcon boxSize={6} mb={4} ml={2} />
                      ) : (
                        <ArrowDownIcon boxSize={6} mb={4} ml={2} />
                      )}
                    </Flex>
                  </Box>
                </AccordionButton>

                <AccordionPanel>
                  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  >
                    <FormControl
                      id="sanguineoRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        ml={{ base: 0, sm: 3 }}
                        display={'flex'}
                        fontWeight="700"
                      >
                        Grupo Sanguíneo
                      </FormLabel>
                      <Select
                        display={'flex'}
                        borderRadius="1rem"
                        placeholder="Seleccione grupo sanguíneo..."
                        _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        name="sanguineoRef"
                        value={formValues.sanguineoRef}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="0">0</option>
                        <option value="AB">AB</option>
                      </Select>
                    </FormControl>
                    <FormControl
                      id="factorRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        /* ml={{ base: 0, sm: 3 }} */
                        display={'flex'}
                        fontWeight="700"
                      >
                        Factor RH
                      </FormLabel>
                      <Select
                        display={'flex'}
                        borderRadius="1rem"
                        placeholder="Seleccione factor RH..."
                        _placeholder={{ opacity: 0.5, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        mb={2}
                        name="factorRef"
                        value={formValues.factorRef}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="+">Positivo</option>
                        <option value="-">Negativo</option>
                      </Select>
                    </FormControl>
                  </HStack>
                  <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  >
                    <FormControl
                      id="obraRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        ml={{ base: 0, sm: 3 }}
                        display={'flex'}
                        fontWeight="700"
                      >
                        Obra Social
                      </FormLabel>
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese obra social..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="obraRef"
                        value={formValues.obraRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                    <FormControl
                      id="numeroRef"
                      display="flex"
                      flexDirection={'column'}
                    >
                      <FormLabel
                        /* mt={{ base: 2, sm: 4 }} */
                        /* ml={{ base: 0, sm: 3 }} */
                        display={'flex'}
                        fontWeight="700"
                      >
                        Número de Afiliado
                      </FormLabel>
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Ingrese numero afiliado..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        /* ml={{ base: 0, sm: '7px' }} */
                        mb={2}
                        type="text"
                        name="numeroRef"
                        value={formValues.numeroRef}
                        onChange={handleInputChange}
                        required
                      />
                    </FormControl>
                  </HStack>
                  {/* <HStack
                    flexWrap={{ base: 'wrap', md: 'nowrap' }}
                    spacing={{ base: 0, md: 4 }}
                  > */}
                  <FormControl
                    id="contactoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Nombre del Contacto de Emergencia
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese nombre de contacto de emergencia..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="contactoRef"
                      value={formValues.contactoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="parentescoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Parentesco del Contacto de Emergencia
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Informe el parentesco..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="parentescoRef"
                      value={formValues.parentescoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  {/* </HStack> */}
                  <FormControl
                    id="telRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Telefono Contacto de Emergencia
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el telefono..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="phone"
                      name="telRef"
                      value={formValues.telRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="medicoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Nombre y apellido médico de cabecera
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el nombre y apellido..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="medicoRef"
                      value={formValues.medicoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="telmedicoRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      Telefono médico de cabecera
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Ingrese el telefono del medico..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="telmedicoRef"
                      value={formValues.telmedicoRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <FormControl
                    id="derivarRef"
                    display="flex"
                    flexDirection={'column'}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                    >
                      ¿A qué lugar derivar en caso de urgencia?
                    </FormLabel>
                    <Input
                      color={'#181718'}
                      display={'flex'}
                      borderRadius="1rem"
                      bg={'#F2F2F2'}
                      placeholder="Indique a donde derivar en una urgencia..."
                      _placeholder={{ opacity: 1, color: 'gray.500' }}
                      overflow="hidden"
                      w={{ base: '314px', md: 'auto' }}
                      ml={{ base: 0, sm: '7px' }}
                      mb={2}
                      type="text"
                      name="derivarRef"
                      value={formValues.derivarRef}
                      onChange={handleInputChange}
                      required
                    />
                  </FormControl>
                  <Text
                    ml={{ base: 0, sm: 3 }}
                    display={'flex'}
                    fontWeight="700"
                    mb={2}
                    mt={2}
                  >
                    Complete las siguientes preguntas:
                  </Text>
                  <FormControl
                    id="enfermedadRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={enfermedadMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Padece alguna enfermedad crónica?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleEnfermedadChange}
                      value={enfermedad}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {enfermedadMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {enfermedadMessage}
                      </FormErrorMessage>
                    )}
                    {enfermedad == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="enfermedadRef"
                        value={formValues.enfermedadRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="medicamentoRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={medicamentoMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Está tomando algún medicamento?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleMedicamentoChange}
                      value={medicamento}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {medicamentoMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {medicamentoMessage}
                      </FormErrorMessage>
                    )}
                    {medicamento == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="medicamentoRef"
                        value={formValues.medicamentoRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="antecedentesRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={antecedentesMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      Antecedentes (Operaciones, convulsiones, etc.)
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleAntecedentesChange}
                      value={antecedentes}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {antecedentesMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {antecedentesMessage}
                      </FormErrorMessage>
                    )}
                    {antecedentes == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="antecedentesRef"
                        value={formValues.antecedentesRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="alergiasRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={alergiasMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Padece alergias de algún tipo?
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleAlergiasChange}
                      value={alergias}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {alergiasMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {alergiasMessage}
                      </FormErrorMessage>
                    )}
                    {alergias == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="alergiasRef"
                        value={formValues.alergiasRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                  <FormControl
                    id="dietaRef"
                    display="flex"
                    flexDirection={'column'}
                    isInvalid={dietaMessage}
                  >
                    <FormLabel
                      /* mt={{ base: 2, sm: 4 }} */
                      ml={{ base: 0, sm: 3 }}
                      display={'flex'}
                      fontWeight="700"
                      mb={0}
                    >
                      ¿Debe seguir alguna dieta especial? (Vegetariana, Vegana,
                      Celiaca, etc.)
                    </FormLabel>

                    <RadioGroup
                      ml={{ base: 0, sm: 3 }}
                      mb={2}
                      display={'flex'}
                      fontWeight="700"
                      onChange={handleDietaChange}
                      value={dieta}
                    >
                      <Stack direction="row">
                        <Radio value="SI">SI</Radio>
                        <Radio value="NO">NO</Radio>
                      </Stack>
                    </RadioGroup>
                    {dietaMessage && (
                      <FormErrorMessage
                        ml={{ base: 0, sm: 3 }}
                        mb={2}
                        display={'flex'}
                        fontWeight="700"
                      >
                        {dietaMessage}
                      </FormErrorMessage>
                    )}
                    {dieta == 'SI' ? (
                      <Input
                        color={'#181718'}
                        display={'flex'}
                        borderRadius="1rem"
                        bg={'#F2F2F2'}
                        placeholder="Especifique por favor..."
                        _placeholder={{ opacity: 1, color: 'gray.500' }}
                        overflow="hidden"
                        w={{ base: '314px', md: 'auto' }}
                        ml={{ base: 0, sm: '7px' }}
                        mb={2}
                        type="text"
                        name="dietaRef"
                        value={formValues.dietaRef}
                        onChange={handleInputChange}
                        required
                      />
                    ) : null}
                  </FormControl>
                </AccordionPanel>
              </Box>
            </Stack>
          </AccordionItem>
          <Stack spacing={2} alignItems={{ base: 'center', sm: 'center' }}>
            <Button
              w={{ base: '314px', md: '505px' }}
              mb={4}
              type="submit"
              borderRadius="2rem"
              marginLeft={{ base: 0, sm: 0 }}
              fontSize={'xl'}
              fontWeight="700"
              loadingText="Submitting"
              bgGradient={'linear(to-r, #233D72, #1E2F48)'}
              color={'#FFFFFF'}
              _hover={{
                bgGradient: 'linear(to-tl, #233D72, #1E2F48)',
              }}
            >
              Registrarse
            </Button>
          </Stack>
        </Accordion>
      </form>
    </>
  );
}

export default InscripcionDelegados;
