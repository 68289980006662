import { Box, Image, Text, Flex, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import pdfFile from '../files/AUTORIZACION_PARTICIPANTES1.pdf';

function ParticipanteSuccess() {
  const location = useLocation();
  const delegadoData = location.state?.delegadoData || null;

  const generatePDF = result => {
    const doc = new jsPDF();

    doc.setFontSize(10);
    doc.setFont('helvetica');

    const text = 'AUTORIZACIÓN PARTICIPANTES';
    const textWidth = doc.getStringUnitWidth(text) * doc.internal.getFontSize();
    const centerX = (doc.internal.pageSize.width - textWidth) / 2;

    // Add text to the PDF
    doc.setFont('helvetica', 'bold');
    doc.text(`ID: ${result.id}`, 10, 5);
    doc.text(text, centerX, 10);
    doc.setFont('helvetica', 'normal');
    doc.text(
      'En mi carácter de padre/madre/tutor/representante (tachar lo que no corresponda) (en adelante,',
      centerX,
      20
    );
    doc.text(
      `el “Autorizante”) de ${result.nombre} ${result.apellido} DNI: ${result.dni} (en adelante, el/la "Participante"), por medio`,
      centerX,
      25
    );
    doc.text('de la presente declaro que:', centerX, 30);
    doc.text(
      '1. Autorizo expresamente al Participante a participar en las actividades desarrolladas por Asociación',
      centerX,
      40
    );
    doc.text(
      'Conciencia (en adelante, indistintamente “Conciencia”) para el Programa Educativo "Uniendo Metas',
      centerX,
      45
    );
    doc.text(
      'Bragado” (en adelante, el “Programa") a realizarse en la localidad de Bragado',
      centerX,
      50
    );
    doc.text(
      '2. Autorizo expresamente a Conciencia y a sus colaboradores a recolectar, utilizar y ceder todos los datos',
      centerX,
      60
    );
    doc.text(
      'personales, imágenes, testimonios, fotografías, videos y material gráfico y/o audiovisual del Participante,',
      centerX,
      65
    );
    doc.text(
      '(en adelante, indistintamente, los “Datos”) que fueran recolectados en el marco del Programa. Estos',
      centerX,
      70
    );
    doc.text(
      'Datos podrán ser editados y cedidos sin necesidad de autorización adicional, con finalidades relacionadas',
      centerX,
      75
    );
    doc.text(
      ' a la promoción y divulgación del Programa a través de cualquier medio de comunicación nacional e',
      centerX,
      80
    );
    doc.text(
      'internacional y sin derecho a recibir compensación alguna por ello.',
      centerX,
      85
    );

    doc.text(
      '3. He sido debidamente informado del alcance de la presente autorización, declarando no tener nada que',
      centerX,
      95
    );
    doc.text(
      'reclamar a Conciencia o a sus colaboradores por ningún concepto directo o indirecto relacionado con el',
      centerX,
      100
    );
    doc.text(
      'uso de los Datos del Participante en el Programa, que se pudieran suscitar como consecuencia y/o en',
      centerX,
      105
    );
    doc.text('ocasión de su participación en el Programa.', centerX, 110);

    doc.text(
      '4. He sido debidamente informado de que los Datos Personales seran recolectados en cumplimiento a la',
      centerX,
      120
    );
    doc.text(
      'Legislacion de Privacidad y seran incorporados en la base de datos de Conciencia ,ante quien podre',
      centerX,
      125
    );
    doc.text(
      'ejercer mi derecho de acceso. Asimismo, se me ha informado que (i) El titular de los datos personales',
      centerX,
      130
    );
    doc.text(
      'tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores',
      centerX,
      135
    );
    doc.text(
      'a seis meses, salvo que se acredite un interes legitimo al efecto, conforme lo establecido en el articulo 14,',
      centerX,
      140
    );
    doc.text(
      'inciso 3 de la Ley N° 25.326. (ii) El titular de los Datos personales podra en cualquier momento solicitar',
      centerX,
      145
    );
    doc.text(
      'el retiro o bloqueo de su nombre de los bancos de datos de Conciencia. (iii) El titular de los datos podra',
      centerX,
      150
    );
    doc.text(
      'ejercer el derecho de acceso, rectificacion, actualizacion, y supresion en forma gratuita en Tucuman 731,',
      centerX,
      155
    );
    doc.text(
      'piso 1, oficina "A", Ciudad Autonoma de Buenos Aires o enviando un correo electronico a',
      centerX,
      160
    );
    doc.text(
      'uniendometas@conciencia.org acreditando su identidad. (iv) La Agencia de Accesos a la informacion',
      centerX,
      165
    );
    doc.text(
      'Publica, en su caracter de Organo de Control de la Ley N° 25.326, tiene la atribucion de atender las',
      centerX,
      170
    );
    doc.text(
      'denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento',
      centerX,
      175
    );
    doc.text(
      'de las normas vigentes en materia de proteccion de datos personales. (v) Todos los Datos que sean',
      centerX,
      180
    );
    doc.text(
      'recolectados con finalidades distintas a las mencionadas seran en virtud de la relacion existente entre el',
      centerX,
      185
    );
    doc.text(
      'titular de los Datos y Conciencia conforme las excepciones establecidas en el inciso 2, literales c) y d) del',
      centerX,
      190
    );
    doc.text('articulo 5 de la Ley 25.326.', centerX, 195);

    doc.text(
      'Firma del Autorizante ____________________________',
      centerX,
      210
    );
    doc.text('Aclaracion: ____________________________________', centerX, 215);
    doc.text('DNI: __________________________________________', centerX, 220);
    doc.text(
      'Relacion con el Participante: _____________________',
      centerX,
      225
    );
    doc.text('Lugar y fecha: _________________________________', centerX, 230);
    doc.text('_______________________________________________', centerX, 245);
    doc.text(
      'Firma y sello del director del establecimiento educativo',
      centerX,
      250
    );

    doc.setFont('helvetica', 'bold');
    doc.text('CERTIFICACION MEDICA', centerX, 260);
    doc.setFont('helvetica', 'normal');
    doc.text(
      `Certifico que ${result.nombre} ${result.apellido} de ____ años ha sido analizado clinicamente y se encuentra`,
      centerX,
      270
    );
    doc.text(
      'apto/a para participar en las actividades del Programa "Uniendo Metas Bragado".',
      centerX,
      275
    );

    doc.text('Firma del Medico ____________________________', centerX, 290);

    // Add more text and formatting based on your requirements
    // Example: doc.text('Participant Name: ' + result.participantName, 20, 30);

    // Save the PDF or open in a new tab
    doc.save('AUTORIZACION_PARTICIPANTES.pdf');
  };

  return (
    <>
      <Box position="relative">
        <Image
          src={require('../images/bg_inscripciones.jpg')}
          w={'100vw'}
          objectFit="cover"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          backgroundColor="rgba(0, 0, 0, 0.35)"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Text
            color="white"
            fontSize={{ base: '28px', md: '100px' }}
            fontWeight="black"
          >
            INSCRIPCIONES
          </Text>
        </Box>
      </Box>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
        /* h={{ md: '40vh' }} */
      >
        <Box
          /* ml={{ base: '30px', md: '100px' }} */
          mt={{ base: '10px', md: '30px' }}
          mb={{ base: '0px', md: '30px' }}
          color={'black'}
          p={4}
          flex={{ base: 0, md: 1 }}
          textAlign={'center'}
        >
          <Text
            /* color="white" */
            fontSize={{ base: '28px', md: '60px' }}
            fontWeight="black"
            textAlign={'center'}
          >
            INSCRIPCIÓN EXITOSA
          </Text>
          <Text fontSize={{ base: '20px', md: '20px' }}>
            Su inscripción se ha realizado con <strong>éxito</strong>.
            <br /> A continuación, le pedimos que{' '}
            <strong>descargue el formulario</strong> haciendo click en el{' '}
            <strong>botón de abajo</strong>,
            <br /> lo complete y lo entregue a su{' '}
            <strong>
              docente a cargo junto con el pago correspondiente de la
              inscripción.
            </strong>
          </Text>
          <Button
            size={'lg'}
            color={'white'}
            bgGradient={'linear(to-r, #233D72, #1E2F48)'}
            _hover={{
              bgGradient: 'linear(to-l, #233D72, #1E2F48)',
            }}
            onClick={() => {
              generatePDF(delegadoData);
            }}
            mt={4}
          >
            DESCARGAR PDF
          </Button>
        </Box>
      </Flex>
    </>
  );
}

export default ParticipanteSuccess;
