import { Flex, Box, Image, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  UilEnvelope,
  UilFacebookF,
  UilInstagram,
  UilTwitterAlt,
} from '@iconscout/react-unicons';

function Contacto() {
  return (
    <>
      <Flex
        bg={'white'}
        alignItems="center"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box
          id="contacto"
          mt={{ base: '10px', md: '30px' }}
          mb={{ base: '0px', md: '30px' }}
          color={'black'}
          p={4}
          flex={{ base: 0, md: 1 }}
        >
          <Text
            fontSize={{ base: '20px', md: '40px' }}
            fontWeight={'black'}
            lineHeight={'42px'}
            mb={2}
            display={'flex'}
            justifyContent={'center'}
          >
            Contacto
          </Text>
          <Text
            display={'flex'}
            justifyContent={'center'}
            style={{ textAlign: 'center' }}
          >
            Contactate con nosotros a través de nuestras redes sociales o por
            mail
          </Text>
          <Box display={'flex'} justifyContent={'center'} mt={6}>
            <Button>
              <Link
                to="https://mail.google.com/mail/?view=cm&source=mailto&to=mnubragado@gmail.com"
                target="_blank"
              >
                <UilEnvelope size={'35px'} />
              </Link>
            </Button>
            <Button>
              <Link to="https://www.instagram.com/umbragado/" target="_blank">
                <UilInstagram size={'35px'} />
              </Link>
            </Button>
            <Button>
              <Link
                to="https://www.facebook.com/ModeloNacionesUnidasBragado"
                target="_blank"
              >
                <UilFacebookF size={'35px'} />
              </Link>
            </Button>
            <Button>
              <Link to="https://twitter.com/UM_Bragado" target="_blank">
                <UilTwitterAlt size={'35px'} />
              </Link>
            </Button>
          </Box>
        </Box>
      </Flex>

      {/* <Flex>
        <Box bg="white" p={4} w={'100vw'}>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={'40px'}
            mt={'40px'}
          >
            <Box
              w="20%"
              h="334px"
              ml={'100px'}
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #7DCDFE,#62B4E4)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #91B451,#73A951)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #FEB81A,#FF9D1C)'}
            ></Box>
            <Box
              w="20%"
              h="334px"
              mr={'100px'}
              borderRadius={'40px'}
              bgGradient={'linear(to-r, #DF2631,#A6213F)'}
            ></Box>
          </Box>
        </Box>
      </Flex> */}
    </>
  );
}

export default Contacto;
