import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  multiTab: false,
});

export const signInWithEmail = async (email, password) => {
  const result = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  return result;
};

export const signUpWithEmail = async data => {
  const result = await supabase.auth.signUp(data);
  return result;
};

export const insertEscuelas = async data => {
  const result = await supabase.from('escuelas').insert(data);
  return result;
};

export const insertEscuelasPendientes = async data => {
  const result = await supabase.from('escuelas_pendientes').insert(data);
  return result;
};

export const insertOtros = async data => {
  const result = await supabase.from('autoridades_prensa_coordi').insert(data).select();
  return result;
};

export const insertDocentes = async data => {
  const result = await supabase.from('docentes').insert(data);
  return result;
};

export const insertDocentesPendientes = async data => {
  const result = await supabase.from('docentes_pendientes').insert(data);
  return result;
};

export const insertDelegados = async datos => {
  const result = await supabase.from('delegados').insert(datos).select();

  return result;
};

export const insertCapacitadores = async data => {
  const result = await supabase.from('capacitadores').insert(data);
  return result;
};

export const getEscuelas = async () => {
  const { data, error } = await supabase.from('escuelas').select();

  if (error) {
    console.log(error);
  }

  return data;
};

export const getDocentes = async () => {
  const { data, error } = await supabase.from('docentes').select();

  if (error) {
    console.log(error);
  }

  return data;
};

export const getDelegados = async () => {
  const { data, error } = await supabase.from('delegados').select();

  if (error) {
    console.log(error);
  }

  return data;
};

export const getAutoridades = async () => {
  const { data, error } = await supabase
    .from('autoridades_prensa_coordi')
    .select()
    .eq('rol', 'Autoridad');

  if (error) {
    console.log(error);
  }

  return data;
};

export const getPrensa = async () => {
  const { data, error } = await supabase
    .from('autoridades_prensa_coordi')
    .select()
    .eq('rol', 'Prensa');

  if (error) {
    console.log(error);
  }

  return data;
};

export const getCoordi = async () => {
  const { data, error } = await supabase
    .from('autoridades_prensa_coordi')
    .select()
    .eq('rol', 'Coordinacion');

  if (error) {
    console.log(error);
  }

  return data;
};

export const getCapacitadores = async () => {
  const { data, error } = await supabase.from('capacitadores').select();

  if (error) {
    console.log(error);
  }

  return data;
};

export const getEscuelasPendientes = async () => {
  const { data, error } = await supabase.from('escuelas_pendientes').select();

  if (error) {
    console.log(error);
  }

  return data;
};

export const getDocentesPendientes = async () => {
  const { data, error } = await supabase.from('docentes_pendientes').select();

  if (error) {
    console.log(error);
  }

  return data;
};

export const deleteEscuelaPendientes = async elegido => {
  const result = await supabase
    .from('escuelas_pendientes')
    .delete()
    .eq('id', elegido.id);
  return result;
};

export const deleteDocentesPendientes = async elegido => {
  const result = await supabase
    .from('docentes_pendientes')
    .delete()
    .eq('id', elegido.id);
  return result;
};
